import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Calendar, Badge } from 'antd';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY } from '../../../../components/context/reducer/actionTypes';
import { convertArrayToKeyedObject } from '../../../../common/utilities';
import useTableActions from '../../../../hooks/useTableActions';
import useHasPermission from '../../../../hooks/useHasPermission';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import { UserCredentialsContext } from '../../../../components/context/LoginContext';

const CreateRouteList = () => {
  const history = useHistory();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const tableRef = useRef(null);
  const { globalDispatch, fetchItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [key, setKey] = useState([]);
  const [permission, setPermission] = useState(null);
  const { credentials } = useContext(UserCredentialsContext);
  const [tableDataLoader, setTableDataLoader] = useState(false);
  const [employeeName, setEmployeeName] = useState(null);

  useEffect(() => {
    if (credentials.access_token) {
      fetchItem({
        modelName: `/profile`,
        showLoader: true,
      }).then((response) => {
        if (response.is_admin === '1') {
          setPermission('ADMIN');
        } else {
          setPermission('EMPLOYEE');
          setEmployeeName(response.f_name.concat(' ').concat(response.l_name));
        }
      });
    }
  }, []);

  const getEmployeeRoutesList = (pageNumber) => {
    setTableDataLoader(true);
    fetchItem({
      modelName: `/employee-routes?page=${pageNumber}`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        setTotalCount(response.data.total > pageSize ? response.data.total + pageSize : response.data.total);
        setTableDataLoader(false);
        if (permission === 'EMPLOYEE') {
          const _data = [];
          for (let i = 0; i < response.data.data.length; i++) {
            if (
              moment(moment(response.data.data[i].date)?.format('YYYY/MM/DD')).isSameOrAfter(
                moment().format('YYYY/MM/DD'),
              )
            ) {
              _data.push(response.data.data[i]);
            }
          }
          setData(_data);
        } else if (permission === 'ADMIN') {
          setData(response.data.data);
        }
      }
    });
  };

  useEffect(() => {
    if (permission === 'ADMIN') {
      fetchItem({
        modelName: `/users`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const res1 = [];
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].status === '1') {
              res1.push(response.data.data[i]);
            }
          }
          setEmployees(res1);
        }
      });
    }
    getEmployeeRoutesList(page);
  }, [permission, page]);

  useEffect(() => {
    setColumns([
      {
        title: 'Employee',
        field: 'user_id',
        filtering: permission === 'ADMIN',
        sorting: permission === 'ADMIN',
        render: (rowData) =>
          permission === 'ADMIN' ? convertArrayToKeyedObject(employees, 'id', 'f_name')[rowData.user_id] : employeeName,
        lookup: convertArrayToKeyedObject(employees, 'id', 'name'),
      },
      {
        title: 'Date',
        field: 'date',
        filtering: false,
        defaultSort: 'desc',
        sorting: true,
        render: (rowData) => (rowData.date ? moment(rowData.date).format('MM-DD-YYYY') : ''),
        // filterComponent: FilterComponentDate,
      },
      {
        title: 'Area',
        field: 'area',
        filtering: false,
        sorting: true,
      },
      {
        title: 'Employee is available',
        field: 'available',
        filtering: false,
        sorting: false,
        render: (rowData) => (rowData?.orders_id?.length > 0 ? 'Yes' : 'No'),
      },
    ]);
    setKey(key + 1);
  }, [employees, employeeName, data, permission]);

  const actions = useTableActions({
    updatePermission: 'ADMIN',
    deletePermission: 'ADMIN',
    editURL: '/employee-management/create-route/',
    removePATH: 'employee-routes',
    tableRef,
    disableUpdate: (dataRoute) => dataRoute?.area?.length > 0,
  });

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    history.push('/employee-management/create-route');
  };

  const onRowClick = (event, rowData) => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: true });
    if (permission === 'EMPLOYEE') {
      history.push(`/employees/route/${rowData.id}`);
    } else {
      history.push(`/employee-management/create-route/${rowData.id}`);
    }
  };

  const onCalendarClick = (item) => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: true });
    if (permission === 'EMPLOYEE') {
      history.push(`/employees/route/${item.id}`);
    } else {
      history.push(`/employee-management/create-route/${item.id}`);
    }
  };

  const onChangePage = (pageNumber) => {
    setPage(pageNumber === 0 ? 1 : pageNumber);
  };

  const dateCellRender = (value) => {
    let listData = [];
    if (data.filter((x) => moment(x.date).format('YYYY-MM-DD') === moment(value).format('YYYY-MM-DD'))) {
      listData = data.filter((x) => moment(x.date).format('YYYY-MM-DD') === moment(value).format('YYYY-MM-DD'));
    }
    return (
      <>
        {listData.length > 0 && listData[0]?.id && (
          <ul className='events'>
            {listData.map((item) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <li key={item?.id} onClick={() => onCalendarClick(item)}>
                <Badge
                  status='success'
                  text={
                    permission === 'ADMIN'
                      ? convertArrayToKeyedObject(employees, 'id', 'f_name')
                          [item?.user_id]?.concat('-')
                          .concat(item?.area ? item?.area : '')
                      : employeeName.concat('-').concat(item?.area ? item?.area : '')
                  }
                />
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };
  return (
    <div style={{ paddingBottom: '40px' }}>
      <ListsContainer>
        <Table
          key={key}
          title='Routes List'
          columns={columns}
          createSlug='Create a Route'
          exportCsv={false}
          actions={permission === 'ADMIN' ? actions : []}
          createAction={useHasPermission('ADMIN') ? handleCreate : undefined}
          tableRef={tableRef}
          data={data.reverse()}
          onRowClick={onRowClick}
          onChangeRowsPerPage={(pgSize) => {
            setPageSize(pgSize);
          }}
          pageSize={pageSize}
          filtering={false}
          totalCount={totalCount}
          page={page}
          sortableColumns
          onChangePage={onChangePage}
          paging={permission === 'ADMIN'}
          isLoading={tableDataLoader}
        />
        <br />
        <br />
        {permission === 'ADMIN' && (
          <>
            <h3>The calendar of ready routes</h3>
            <Calendar dateCellRender={dateCellRender} />
          </>
        )}
      </ListsContainer>
    </div>
  );
};

export default CreateRouteList;
