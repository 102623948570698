import { makeStyles, useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Button, Card, CardActions, CardContent, CardHeader, Divider } from '@material-ui/core';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link, useHistory } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import { REQUIRED_STRING } from '../../../../constants/HookForm';
import { Map } from '../../../../components/Map/Map';
import BTextField from '../../../../components/inputs/forms/BTextField';
import {
  setValues,
  onChangeForBComponents,
  onKeyDownHandlerOnlyAcceptIntegerEnglish,
  convertArrayToKeyedObject,
} from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useHistoryForms from '../../../../hooks/useHistoryForms';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import { UserCredentialsContext } from '../../../../components/context/LoginContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  customInputFormControl: {
    width: '100%',
  },
  formLabel: {
    fontSize: 16,
    marginBottom: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const initialAssignRoute = {
  date: '',
  user_id: null,
  orders_id: [],
  orderList: [],
  area: '',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name, personName, theme) => ({
  fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
});

const CreateRoutePage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);

  const { viewReadOnly } = globalState;

  const [rerenderMap, setRerenderMap] = useState(0);
  const [newLocation, setNewLocation] = useState([]);

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue, register, unregister } = useForm();
  const [assignedRoute, setAssignedRoute] = useState(initialAssignRoute);
  const [employees, setEmployees] = useState([]);
  const [assignMode, setAssignMode] = useState(false);
  const [orders, setOrders] = useState([]);
  const [intervals, setIntervals] = useState([]);
  const [orderNames, setOrderNames] = useState([]);
  const [markers, setMarkers] = useState([]);
  const theme = useTheme();
  const [assignedOrders, setAssignedOrders] = useState([]);
  const [assignedOrdersMain, setAssignedOrdersMain] = useState([]);
  const [assignedOrdersID, setAssignedOrdersID] = useState([]);
  const [savedOrders, setSavedOrders] = useState([]);
  const [readOnlyOrders, setReadOnlyOrders] = useState([]);
  const [startingPoint, setStartingPoint] = useState(null);
  const [permission, setPermission] = useState(null);
  const [available, setAvailable] = useState(false);
  const { credentials } = useContext(UserCredentialsContext);
  const [createType, setCreateType] = useState('date');
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [foundOrdersByArea, setFoundOrdersByArea] = useState([]);
  const [areaMap, setAreaMap] = useState(false);
  const [areaMarkers, setAreaMarkers] = useState([]);
  const [selectedOrdersByArea, setSelectedOrdersByArea] = useState([]);
  const [centeredPoint, setCenteredPoint] = useState(null);
  const [foundOrders, setFoundOrders] = useState(true);

  useEffect(() => {
    if (credentials.access_token) {
      fetchItem({
        modelName: `/profile`,
        showLoader: true,
      }).then((response) => {
        if (response.is_admin === '1') {
          fetchItem({
            modelName: `/areas`,
            showLoader: true,
          }).then((res) => {
            if (res) {
              const res1 = [];
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].status === '1') {
                  res1.push(res.data[i]);
                }
              }
              setAreas(res1);
            }
          });
          setPermission('ADMIN');
        } else {
          setPermission('EMPLOYEE');
        }
      });
    }
  }, []);
  const { fetchData, HistoryId, historyIdList } = useHistoryForms({
    location: props.location,
    route: '/employee-management/employee-routes',
  });

  const { item: currentRoute, setItem: setCurrentRoute } = useBasicInformationMountedEffect({
    match: props.match,
    historyIdList,
    fetchItemURL: '/employee-routes',
    localStorageKey: 'employee-route',
    initialItem: initialAssignRoute,
    fetchData,
    historyId: HistoryId,
  });

  const handleChange = (event) => {
    const options = event.target.value;
    const value = [];
    for (let i = 0, l = options?.length; i < l; i += 1) {
      value.push(options[i]);
    }
    setAssignedOrders(value);
  };
  const incrementRendererMap = () => setRerenderMap((prev) => prev + 1);
  const handleNewMarkMoved = (point) => point?.lat && setNewLocation([point.lat, point.lng]);

  useEffect(() => {
    if (permission === 'ADMIN') {
      fetchItem({
        modelName: `/users`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const _final = [];
          const { data } = response.data;
          for (let i = 0; i < data.length; i++) {
            if (data[i]?.is_admin === '0' && data[i].status === '1') {
              _final.push(data[i]);
            }
          }
          setEmployees(_final);
        }
      });
      fetchItem({
        modelName: `/intervals`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          setIntervals(response.data);
        }
      });
    }
  }, [permission]);

  useEffect(() => {
    if (!assignMode && createType === 'date') {
      // register(
      //   { name: 'distance' },
      //   {
      //     required: REQUIRED_STRING,
      //   },
      // );
      register(
        { name: 'date' },
        {
          required: REQUIRED_STRING,
        },
      );
    }
  }, []);
  useEffect(() => {
    if (createType === 'area') {
      unregister('date');
    }
  }, [createType]);

  useEffect(() => {
    if (currentRoute.data) {
      setCurrentRoute(currentRoute.data);
    }
    if (currentRoute) {
      setValues(setValue, currentRoute);
    }
  }, [currentRoute]);

  useEffect(() => {
    if (currentRoute.id && areas.length > 0) {
      const _order = currentRoute;
      if (moment(currentRoute.date, true).isValid()) {
        _order.date = moment(currentRoute.date).format('YYYY-MM-DD');
        setCurrentRoute(_order);
      }
      if (currentRoute.area) {
        setSelectedArea(areas.find((x) => x.title === currentRoute.area)?.id);
        setCreateType('area');
        setAreaMap(true);
      }
    }
  }, [currentRoute, areas, savedOrders]);

  useEffect(() => {
    if (assignedRoute) {
      setValues(setValue, assignedRoute);
    }
  }, [assignedRoute]);

  useEffect(() => {
    if (currentRoute.latitude && currentRoute.longitude) {
      incrementRendererMap();
    }
  }, [currentRoute?.latitude, currentRoute?.longitude]);

  useEffect(() => {
    const _currentRoute = currentRoute;
    _currentRoute.latitude = newLocation[0] ? newLocation[0] : null;
    _currentRoute.longitude = newLocation[1] ? newLocation[1] : null;
  }, [newLocation]);

  useEffect(() => {
    fetchItem({
      modelName: `/orders`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const { data } = response.data;
        const res1 = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].status === '1') {
            res1.push(data[i]);
          }
        }
        setSavedOrders(res1);
      }
    });
  }, []);

  useEffect(() => {
    const _markers = [];
    const _orderNames = [];
    if (orders.length > 0 && intervals.length > 0) {
      for (let i = 0; i < orders.length; i++) {
        if (
          !orders[i].user_id &&
          savedOrders.find((x) => String(x.id) === String(orders[i].id)).order_status.title === 'Open'
        ) {
          const intervalId = savedOrders.find((x) => String(x.id) === String(orders[i].id)).interval_id;
          const interval = intervals.find((x) => String(x.id) === String(intervalId));
          if (permission === 'ADMIN') {
            _markers.push({
              position:
                orders[i].full_address === orders[i + 1]?.full_address
                  ? [orders[i].latitude + 0.0003, orders[i].longitude + 0.0003]
                  : [orders[i].latitude, orders[i].longitude],
              tooltip: `${_markers.length + 1} ${String(orders[i].order_number)}`,
              id: orders[i].id,
              same: orders[i].full_address === orders[i + 1]?.full_address ? 'same' : '',
            });
          }
          if (permission === 'EMPLOYEE') {
            _markers.push({
              position: [orders[i].latitude, orders[i].longitude],
              tooltip: `${_markers.length + 1}`,
              id: orders[i].id,
            });
          }
          _orderNames.push(
            String(orders[i].order_number)
              .concat('  ---  ')
              .concat(String(interval.min))
              .concat(' - ')
              .concat(String(interval.max)),
          );
        }
      }
      setOrderNames(_orderNames);
      setMarkers(_markers);
    }
  }, [orders, intervals]);

  useEffect(() => {
    const _orders = [];
    for (let i = 0; i < assignedOrders.length; i++) {
      if (assignedOrders[i].includes('-')) {
        const _index = assignedOrders[i].indexOf('-');
        const final = assignedOrders[i].substring(0, _index).replace(' ', '').replace(' ', '');
        _orders.push(final);
      } else {
        _orders.push(assignedOrders[i]);
      }
    }
    setAssignedOrdersMain(_orders);
  }, [assignedOrders]);

  useEffect(() => {
    if (startingPoint === 'employee' && assignedRoute.user_id) {
      fetchItem({
        modelName: `/users`,
        id: assignedRoute.user_id,
        showLoader: true,
      }).then((response) => {
        if (response.data) {
          const { data } = response.data;
          const _route = { ...currentRoute };
          _route.latitude = data.latitude;
          _route.longitude = data.longitude;
          setCurrentRoute(_route);
        }
      });
    } else {
      const _route = { ...currentRoute };
      _route.latitude = '';
      _route.longitude = '';
      setCurrentRoute(_route);
    }
  }, [startingPoint]);

  useEffect(() => {
    const _markers = [];
    const _orders = [];
    const _ordersID = [];
    for (let i = 0; i < assignedOrdersMain.length; i++) {
      const order = orders.find((x) => String(x.order_number) === String(assignedOrdersMain[i]));
      const orderId = orders.find((x) => String(x.order_number) === String(assignedOrdersMain[i]))?.id;
      if (order && orderId) {
        _orders.push(order);
        _ordersID.push(orderId);
      }
    }
    if (_orders.length > 0) {
      for (let i = 0; i < _orders.length; i++) {
        if (permission === 'ADMIN') {
          _markers.push({
            position:
              orders[i].full_address === orders[i + 1]?.full_address
                ? [_orders[i].latitude + 0.0003, _orders[i].longitude + 0.0003]
                : [_orders[i].latitude, _orders[i].longitude],
            tooltip: `${i + 1} ${String(_orders[i].order_number)}`,
            id: orders[i].id,
            same: orders[i].full_address === orders[i + 1]?.full_address ? 'same' : '',
          });
        }
        if (permission === 'EMPLOYEE') {
          _markers.push({
            position: [_orders[i].latitude, _orders[i].longitude],
            tooltip: `${i + 1}`,
            id: orders[i].id,
          });
        }
      }
    }
    setMarkers(_markers);
    setAssignedOrdersID(_ordersID);
  }, [assignedOrdersMain]);

  useEffect(() => {
    if (viewReadOnly) {
      if (readOnlyOrders.length === 0) {
        setAvailable(true);
      } else {
        setAvailable(false);
      }
    }
  }, [readOnlyOrders]);

  const onSubmit = () => {
    setLoading(true);
    if (createType === 'area') {
      const route = {
        orders_id: [],
        area: null,
        date: currentRoute.date,
        user_id: currentRoute.user_id,
        orderList: [],
      };
      if (selectedOrdersByArea.length === 0) {
        BSwalShowError(['Please select the orders.']);
        setLoading(false);
        return;
      }
      const orderIds = [];
      for (let i = 0; i < selectedOrdersByArea.length; i++) {
        orderIds.push(selectedOrdersByArea[i].id);
      }
      route.orders_id = orderIds;
      route.area = areas.find((x) => String(x.id) === String(selectedArea)).title;
      saveItem('employee-routes', route, currentRoute?.id || null)
        .then(() => {
          if (route.user_id) {
            for (let i = 0; i < route.orders_id.length; i++) {
              const _user = {
                user_id: route.user_id,
                order_number: `FRE${route.orders_id[i]}`,
                route_date: currentRoute.date,
              };
              saveItem(`orders/send-order-mail/${route.orders_id[i]}`, _user).catch(() =>
                BSwalShowError([`The pdf for order ${route.orders_id[i]} not found`]),
              );
            }
          }
          history.push('/employee-management/create-routes');
        })
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoading(false));
    } else if (!assignMode && !available) {
      if (!currentRoute.distance) {
        BSwalShowError(['Please select the distance.']);
        setLoading(false);
        return;
      }
      if (!currentRoute.latitude || !currentRoute.longitude) {
        BSwalShowError(['Please select the exact location of the address on the map.']);
        setLoading(false);
        return;
      }
      const route = {
        date: moment(currentRoute.date).format('MM-DD-YYYY HH:mm:ss'),
        distance: Number(currentRoute.distance),
        latitude: currentRoute.latitude,
        longitude: currentRoute.longitude,
      };
      saveItem('orders/search', route, null)
        .then((response) => {
          if (response.data.data.length > 0) {
            let permit = false;
            for (let i = 0; i < response.data.data.length; i++) {
              if (
                !response.data.data[i].user_id &&
                savedOrders.find((x) => x.id === response.data.data[i].id).order_status.title === 'Open'
              ) {
                permit = true;
              }
            }
            if (!permit) {
              BSwalShowError(['No order found for the selected date and location. Please try for another entries.']);
              setAssignMode(false);
            } else {
              setAssignMode(true);
              const _assignedRoute = assignedRoute;
              _assignedRoute.date = currentRoute.date;
              setAssignedRoute(_assignedRoute);
              setOrders(response.data.data);
            }
          } else {
            BSwalShowError(['No order found for the selected date and location. Please try for another entries.']);
            setAssignMode(false);
          }
        })
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoading(false));
    } else {
      if (!assignedRoute.user_id) {
        BSwalShowError(['Please select the employee.']);
        setLoading(false);
        return;
      }
      if (assignedOrders.length === 0 && !available) {
        BSwalShowError(['Please select the location of orders.']);
        setLoading(false);
        return;
      }
      if (available) {
        assignedRoute.date = currentRoute.date;
      }
      const route = { ...assignedRoute };
      route.orders_id = assignedOrdersID;
      saveItem('employee-routes', route, null)
        .then(() => history.push('/employee-management/create-routes'))
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoading(false));
    }
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentRoute, setCurrentRoute);
  };

  const onChangeRoute = (e) => {
    onChangeForBComponents(e, setValue, assignedRoute, setAssignedRoute);
  };

  const markerSelected = (markerId) => {
    const _selected =
      currentRoute.orderList.length > 0 && foundOrdersByArea.length === 0
        ? currentRoute.orderList.find((x) => String(x.id) === String(markerId))
        : foundOrdersByArea.find((x) => String(x.id) === String(markerId));
    if (!_selected) {
      const _selectedRoute = currentRoute.orderList.find((x) => String(x.id) === String(markerId));
      if (_selectedRoute && !selectedOrdersByArea.includes(_selectedRoute)) {
        setCenteredPoint([_selectedRoute.latitude, _selectedRoute.longitude]);
        setSelectedOrdersByArea((oldArray) => [...oldArray, _selectedRoute]);
      }
      return;
    }
    if (_selected && !selectedOrdersByArea.includes(_selected)) {
      setCenteredPoint([_selected.latitude, _selected.longitude]);
      setSelectedOrdersByArea((oldArray) => [...oldArray, _selected]);
    }
  };

  const searchOrdersByArea = () => {
    if (selectedArea) {
      const _search = {
        area_id: selectedArea,
      };
      saveItem('orders/search-area', _search, null).then((res) => {
        const _orders = [];
        for (let i = 0; i < res.data?.data.length; i++) {
          if (res.data?.data[i].status === '1') {
            _orders.push(res.data?.data[i]);
          }
        }
        if (res.data?.data.length === 0) {
          setFoundOrders(false);
        }
        setFoundOrdersByArea(_orders);
      });
    }
  };

  useEffect(() => {
    if (foundOrdersByArea.length === 0 && foundOrders) {
      searchOrdersByArea();
    }
    if (foundOrdersByArea.length > 0) {
      const _markers = [];
      const savedAreaOrders = [];
      for (let i = 0; i < foundOrdersByArea.length; i++) {
        if (currentRoute.id) {
          for (let j = 0; j < currentRoute.orderList.length; j++) {
            const _order = currentRoute.orderList[j];
            if (_order) {
              _markers.push({
                position:
                  currentRoute.orderList[j].full_address === currentRoute.orderList[j + 1]?.full_address
                    ? [_order.latitude + 0.0003, _order.longitude + 0.0003]
                    : [_order.latitude, _order.longitude],
                tooltip: `${String(_order.order_number)}`,
                id: _order.id,
                same:
                  currentRoute.orderList[j].full_address === currentRoute.orderList[j + 1]?.full_address ? 'same' : '',
              });
            }
            const _selected = currentRoute.orderList[j];
            if (_selected && !savedAreaOrders.includes(_selected)) {
              savedAreaOrders.push(_selected);
            }
          }
          setSelectedOrdersByArea(savedAreaOrders);
        }
        if (!foundOrdersByArea[i].user_id && String(foundOrdersByArea[i].order_status_id) === '1') {
          if (!viewReadOnly) {
            _markers.push({
              position:
                foundOrdersByArea[i].full_address === foundOrdersByArea[i + 1]?.full_address
                  ? [foundOrdersByArea[i].latitude + 0.0003, foundOrdersByArea[i].longitude + 0.0003]
                  : [foundOrdersByArea[i].latitude, foundOrdersByArea[i].longitude],
              tooltip: `${String(foundOrdersByArea[i].order_number)}`,
              id: foundOrdersByArea[i].id,
              same: foundOrdersByArea[i].full_address === foundOrdersByArea[i + 1]?.full_address ? 'same' : '',
            });
          }
        }
      }
      if (_markers.length === 0) {
        BSwalShowError(['No orders found for this area.']);
      }
      if (_markers.length > 0) {
        const uniqueArray = _markers.filter((value, index) => {
          const _value = JSON.stringify(value);
          return index === _markers.findIndex((obj) => JSON.stringify(obj) === _value);
        });
        setAreaMarkers(uniqueArray);
        setAreaMap(true);
      }
    }
  }, [foundOrdersByArea, selectedArea]);

  const createRouteByArea = () => {
    setSelectedArea('');
    if (createType === 'date') {
      setCreateType('area');
    } else {
      setCreateType('date');
    }
  };

  useEffect(() => {
    if (viewReadOnly || currentRoute.id) {
      const _markers = [];
      const _readOnly = [];
      const savedAreaOrders = [];
      if (currentRoute.orderList?.length > 0) {
        for (let i = 0; i < currentRoute.orderList.length; i++) {
          const point = currentRoute.orderList[i];
          const intervalId = currentRoute.orderList[i]?.interval_id;
          const interval = intervals.find((x) => String(x.id) === String(intervalId));
          if (point) {
            _readOnly.push(
              String(point.order_number)
                .concat('  ---  ')
                .concat(String(interval?.min))
                .concat(' - ')
                .concat(String(interval?.max)),
            );
            if (permission === 'ADMIN') {
              _markers.push({
                position:
                  currentRoute.orderList[i].full_address === currentRoute.orderList[i + 1]?.full_address
                    ? [point.latitude + 0.0003, point.longitude + 0.0003]
                    : [point.latitude, point.longitude],
                tooltip: `${String(point.order_number)}`,
                id: point.id,
                same:
                  currentRoute.orderList[i].full_address === currentRoute.orderList[i + 1]?.full_address ? 'same' : '',
              });
            }
            if (permission === 'EMPLOYEE') {
              _markers.push({
                position: [point.latitude, point.longitude],
                tooltip: `${i + 1}`,
                id: point.id,
              });
            }
            if (point && !savedAreaOrders.includes(point)) {
              savedAreaOrders.push(point);
            }
          }
        }
        setMarkers(_markers);
        setSelectedOrdersByArea(savedAreaOrders);
        if (_markers.length > 0) {
          setAreaMarkers(_markers);
        }
        setReadOnlyOrders(_readOnly);
      }
    }
  }, [permission, intervals, currentRoute.orderList]);

  const deleteOrderFromRoute = (item) => {
    const _selected = [...selectedOrdersByArea];
    _selected.splice(item, 1);
    setSelectedOrdersByArea(_selected);
  };

  let ordersForRoute = [];
  for (let i = 0; i < selectedOrdersByArea.length; i++) {
    ordersForRoute.push(
      <div style={{ padding: '15px' }}>
        <div>
          {selectedOrdersByArea[i]?.order_number}
          <HighlightOffIcon
            onClick={() => deleteOrderFromRoute(i)}
            style={{ position: 'relative', top: '7px', right: '-5px' }}
          />
        </div>
      </div>,
    );
  }
  if (selectedOrdersByArea.length === 0) {
    ordersForRoute = [];
  }

  return (
    <div style={{ paddingBottom: '40px' }}>
      {currentRoute && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: permission === 'ADMIN' ? '/employee-management/create-routes' : '/employees/routes',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}
          >
            <ArrowBackIcon />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title={viewReadOnly ? 'View Route' : 'Create Route'} />
            <Divider />
            <CardContent>
              <br />
              {permission === 'ADMIN' && !viewReadOnly && !currentRoute.id && (
                <>
                  <Button id='create' onClick={createRouteByArea} color='secondary' variant='outlined'>
                    {createType === 'area' ? 'Create route by date' : 'Create route by area'}
                  </Button>
                  <br />
                  <br />
                </>
              )}
              {createType === 'area' && (
                <Grid container spacing={3}>
                  <BSelectField
                    sm={4}
                    xs={12}
                    label='Select Area'
                    required
                    name='area_id'
                    value={selectedArea}
                    onChange={(e) => setSelectedArea(e.currentTarget?.value)}
                    error={errors.area_id}
                    items={convertArrayToKeyedObject(areas, 'id', 'title')}
                    readOnly={viewReadOnly || currentRoute.id}
                  />
                  {!currentRoute.id && (
                    <Button
                      id='searchArea'
                      onClick={searchOrdersByArea}
                      color='secondary'
                      variant='outlined'
                      style={{ height: '40px', marginTop: '60px' }}
                    >
                      Search
                    </Button>
                  )}
                  {areaMap && (
                    <Grid container spacing={1}>
                      {ordersForRoute}
                      <Grid item sm={12} style={{ marginTop: '20px', height: '620px', width: '100%' }}>
                        <Map
                          key={rerenderMap}
                          center={
                            // eslint-disable-next-line no-nested-ternary
                            centeredPoint
                              ? [centeredPoint[0], centeredPoint[1]]
                              : areaMarkers.length > 0
                              ? [areaMarkers[0]?.position[0], areaMarkers[0]?.position[1]]
                              : null
                          }
                          marker={
                            assignMode || viewReadOnly
                              ? null
                              : {
                                  position: [currentRoute.latitude, currentRoute.longitude],
                                  popup: '',
                                }
                          }
                          markers={areaMarkers}
                          onNewMarkerMoved={handleNewMarkMoved}
                          canAddMarker={false}
                          routing={viewReadOnly}
                          permission={permission}
                          onClicks={markerSelected}
                        />
                      </Grid>
                      <BSelectField
                        sm={4}
                        xs={12}
                        label='Employee'
                        name='user_id'
                        value={currentRoute.user_id}
                        onChange={(e) => onChangeForBComponents(e, setValue, currentRoute, setCurrentRoute)}
                        error={errors.user_id}
                        items={convertArrayToKeyedObject(employees, 'id', 'name')}
                        readOnly={viewReadOnly}
                      />
                      <BDateFieldTable
                        sm={4}
                        min={moment()}
                        label='Date'
                        name='date'
                        value={currentRoute.date}
                        onChange={(e) => onChangeForBComponents(e, setValue, currentRoute, setCurrentRoute)}
                        error={errors.date}
                        readOnly={viewReadOnly}
                      />
                      <br />
                      <br />
                    </Grid>
                  )}
                </Grid>
              )}
              {createType === 'date' && (
                <Grid container spacing={3}>
                  {permission === 'ADMIN' && (
                    <BSelectField
                      sm={4}
                      xs={12}
                      label='Employee'
                      required
                      name='user_id'
                      value={viewReadOnly ? currentRoute.user_id : assignedRoute.user_id}
                      onChange={onChangeRoute}
                      error={errors.user_id}
                      items={convertArrayToKeyedObject(employees, 'id', 'name')}
                      readOnly={viewReadOnly}
                    />
                  )}
                  {permission === 'ADMIN' && (
                    <BDateFieldTable
                      sm={4}
                      label='Date'
                      name='date'
                      value={currentRoute.date}
                      onChange={(e) => onChangeForBComponents(e, setValue, currentRoute, setCurrentRoute)}
                      error={errors.date}
                      required
                      readOnly={assignMode || viewReadOnly}
                    />
                  )}
                  {(!viewReadOnly || (viewReadOnly && available)) && (
                    <BCheckBoxField
                      sm={4}
                      style={{ marginTop: '40px' }}
                      name='is_available'
                      label='Employee is not available'
                      value={available}
                      error={errors.is_admin}
                      onChange={(e) => setAvailable(e.currentTarget.value)}
                      readOnly={viewReadOnly}
                    />
                  )}
                  {!viewReadOnly && !available && (
                    <BTextField
                      label='Distance'
                      name='distance'
                      value={currentRoute?.distance}
                      onChange={onChange}
                      onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                      error={errors.distance}
                      readOnly={assignMode}
                      required
                    />
                  )}
                  {((assignMode && orders.length > 0) || (viewReadOnly && permission === 'ADMIN')) && !available && (
                    <Grid item sm={12} style={{ marginTop: '20px' }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id='demo-mutiple-chip-label' style={{ top: '-20px' }}>
                          Orders
                        </InputLabel>
                        <Select
                          labelId='demo-mutiple-chip-label'
                          id='demo-mutiple-chip'
                          multiple
                          disabled={viewReadOnly}
                          value={viewReadOnly ? readOnlyOrders : assignedOrders}
                          onChange={handleChange}
                          input={<Input id='select-multiple-chip' />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {orderNames?.map((name) => (
                            <MenuItem key={name} value={name} style={getStyles(name, assignedOrders, theme)}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {!viewReadOnly && !available && (
                    <Grid item sm={12} xs={12} style={{ marginTop: '20px' }}>
                      <FormControl
                        component='fieldset'
                        style={{ width: '100%' }}
                        disabled={!assignedRoute.user_id || assignMode}
                      >
                        <FormLabel component='legend'>Starting Point</FormLabel>
                        <RadioGroup
                          aria-label='Order Type'
                          name='starting_point'
                          value={startingPoint}
                          onChange={(e) => setStartingPoint(e.currentTarget.value)}
                          style={{ display: 'inline' }}
                        >
                          <FormControlLabel value='employee' control={<Radio />} label='Employee Address' />
                          <FormControlLabel value='map' control={<Radio />} label='Select on the map' />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  {!available && (
                    <Grid container spacing={1}>
                      <Grid item sm={12} style={{ marginTop: '20px', height: '620px', width: '100%' }}>
                        <Map
                          key={rerenderMap}
                          center={
                            viewReadOnly && markers[0]?.position[0]
                              ? [markers[0]?.position[0], markers[0]?.position[1]]
                              : null
                          }
                          marker={
                            assignMode || viewReadOnly
                              ? null
                              : {
                                  position: [currentRoute.latitude, currentRoute.longitude],
                                  popup: '',
                                }
                          }
                          markers={markers}
                          onNewMarkerMoved={handleNewMarkMoved}
                          canAddMarker={viewReadOnly || startingPoint === 'employee' ? false : !assignMode}
                          routing={viewReadOnly}
                          permission={permission}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && createType === 'date' && (
                  <BSubmitButton
                    id='submitEmployee'
                    // eslint-disable-next-line no-nested-ternary
                    label={available ? 'save empty route' : assignMode ? 'Assign Orders to Employee' : 'Get Orders'}
                    loading={loading}
                  />
                )}
                {!viewReadOnly && createType === 'area' && (
                  <BSubmitButton
                    id='submitEmployeeArea'
                    // eslint-disable-next-line no-nested-ternary
                    label={currentRoute.id ? 'Edit route' : 'Create Route'}
                    loading={loading}
                  />
                )}
              </Grid>
              <Grid
                className='employee-btn'
                item
                xs={12}
                sm={6}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: permission === 'ADMIN' ? '/employee-management/create-routes' : '/employees/routes',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  Back to Routes list
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

CreateRoutePage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default CreateRoutePage;
