/* eslint-disable no-undef */
import { makeStyles, useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import loadImage from 'blueimp-load-image';
import { Alert, Badge, Calendar } from 'antd';
import moment from 'moment';
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import { Map } from '../../../../components/Map/Map';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';
import BImageList from '../../../../components/inputs/forms/BImageList';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import { REQUIRED_STRING } from '../../../../constants/HookForm';
import BTextField from '../../../../components/inputs/forms/BTextField';
import {
  convertArrayToKeyedObject,
  convertArrayToKeyedObjects,
  normalizeUsPhone,
  onChangeForBComponents,
  onChangeTable,
  onKeyDownHandlerOnlyAcceptEmailCharacters,
  onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers,
  onKeyDownHandlerOnlyAcceptIntegerEnglish,
  setValues,
} from '../../../../common/utilities';
import { BSwalShowError, BSwalCustom } from '../../../../common/BSwal';
import useHistoryForms from '../../../../hooks/useHistoryForms';
import BHistoryNavigator from '../../../../components/BHistoryNavigator';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import { UserCredentialsContext } from '../../../../components/context/LoginContext';
import Inventory from './components/Inventory';
import PdfContainer from './components/PdfContainer';
import 'antd/dist/antd.css';
import './OrderPage.css';
import ChatPage from '../../../Chat/ChatPage/ChatPage';
import { logActions } from '../OrderLogs/OrderLogs';
import LoadingDialog from './components/LoadingDialog';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  customInputFormControl: {
    width: '100%',
  },
  formLabel: {
    fontSize: 16,
    marginBottom: 10,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name, personName, theme) => ({
  fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
});

const initialOrder = {
  email: '',
  phone_1: '',
  phone_2: '',
  travel_price: null,
  full_address: '',
  description: '',
  client_id: null,
  user_id: null,
  company_type_id: null,
  company_type: null,
  order_status_id: 1,
  latitude: null,
  longitude: null,
  previous_orders: [],
  special_notes: '',
  technician_report: '',
  job_types: [],
  code: '',
  area_id: null,
  interval_id: null,
  date: '',
  order_type: 'private',
  expenses: null,
  external_order_number: '',
  order_number: '',
  bonus: '',
  expenses_paid_by_employee: true,
  prepaid: false,
  customer_f_name: '',
  customer_l_name: '',
  approved_by_admin: false,
  reopen: false,
  report: false,
  claim_number: '',
  invoice_number: '',
  status: true,
};

const OrdersPage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { globalState, saveItem, fetchItem, removeItem } = useContext(DashboardPanelContext);

  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue, register } = useForm();
  const [clients, setClients] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [jobTypesLabels, setJobTypesLabels] = useState([]);
  const [assignedJobTypes, setAssignedJobTypes] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [rerenderMap, setRerenderMap] = useState(0);
  const [ordersLinksData, setOrdersLinksData] = useState([]);
  const [newLocation, setNewLocation] = useState([]);
  const [key, setKey] = useState(0);
  const [beforeImages, setBeforeImages] = useState([]);
  const [beforeImagesSave, setBeforeImagesSave] = useState([]);
  const [afterImages, setAfterImages] = useState([]);
  const [afterImagesSave, setAfterImagesSave] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [additionalImagesSave, setAdditionalImagesSave] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [deletedImagesAfter, setDeletedImagesAfter] = useState([]);
  const [deletedImagesBefore, setDeletedImagesBefore] = useState([]);
  const [orders, setOrders] = useState([]);
  const [calendarValue, setCalendarValue] = useState(moment().add(0, 'days'));
  const [calendarSelectedValue, setCalendarSelectedValue] = useState(moment().add(0, 'days'));
  const [isDateSelected, setIsDateSelected] = useState(window.location.href.includes('order/'));
  const [areas, setAreas] = useState([]);
  const [intervals, setIntervals] = useState([]);
  const [users, setUsers] = useState([]);
  const [calendarOrders, setCalendarOrders] = useState([]);
  const [bonusId, setBonusId] = useState(null);
  const [searchLat, setSearchLat] = useState('');
  const [searchLang, setSearchLang] = useState('');
  const [zips, setZips] = useState([]);
  const [fullAdress, setFullAddress] = useState('');
  const [permission, setPermission] = useState(null);
  const { credentials } = useContext(UserCredentialsContext);
  const [updates, setUpdates] = useState([]);
  const [paymentLogs, setPaymentLogs] = useState([]);
  const [PdfContain, setPdfContain] = useState(null);
  const [openPdf, setOpenPdf] = useState(false);
  const [userLocation, setUserLocation] = useState([null, null]);
  const [pdfKey, setPdfKey] = useState(0);
  const [notChangedOrder, setNotChangedOrder] = useState(initialOrder);
  const [routesOrder, setRoutesOrder] = useState(null);
  const [intervalPdf, setIntervalPdf] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [techMsg, setTechMsg] = useState(null);
  const [uploadedAdditional, setUploadedAdditional] = useState(0);
  const [uploadedAfter, setUploadedAfter] = useState(0);
  const [uploadedBefore, setUploadedBefore] = useState(0);
  const [additionalImagesUpload, setAdditionalImagesUpload] = useState(null);
  const [beforeImagesUpload, setBeforeImagesUpload] = useState(null);
  const [afterImagesUpload, setAfterImagesUpload] = useState(null);
  /* const [emailMsg, setEmailMsg] = useState({
    customMessage: '',
    customEmail: '',
    customSubject: '',
  }); */

  const theme = useTheme();

  const [columns] = useState([
    {
      title: 'Previous order Number',
      field: 'order_number',
      editComponent: (prop) => (
        <BTextField
          sm={12}
          onChange={(e) => prop.onChange(e.currentTarget.value)}
          value={prop.value}
          onKeyDown={onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers}
        />
      ),
    },
  ]);

  const incrementRendererMap = () => setRerenderMap((prev) => prev + 1);
  const handleNewMarkMoved = (point) => point?.lat && setNewLocation([point.lat, point.lng]);

  const {
    fetchData,
    prevHistory,
    nextHistory,
    HistoryId,
    historyIdList,
    historyStep,
    history: fetchedHistory,
    historyInfo,
  } = useHistoryForms({
    location: props.location,
    route: '/order-management/orders',
  });

  const {
    id,
    item: currentOrder,
    setItem: setCurrentOrder,
  } = useBasicInformationMountedEffect({
    match: props.match,
    historyIdList,
    fetchItemURL: '/orders',
    localStorageKey: 'orders',
    initialItem: initialOrder,
    fetchData,
    historyId: HistoryId,
  });
  useEffect(() => {
    if (credentials.access_token) {
      fetchItem({
        modelName: `/profile`,
        showLoader: true,
      }).then((response) => {
        if (response.is_admin === '1') {
          setPermission('ADMIN');
        } else {
          setPermission('EMPLOYEE');
        }
      });
    }
  }, []);

  useEffect(() => {
    fetchItem({
      modelName: `/orders`,
      showLoader: true,
    }).then((response) => {
      if (response.data) {
        setOrders(response.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (currentOrder?.latitude && currentOrder?.longitude) {
      incrementRendererMap();
    }
  }, [currentOrder?.latitude, currentOrder?.longitude]);

  useEffect(() => {
    setOrdersLinksData(currentOrder?.previous_orders);
  }, [currentOrder?.previous_orders]);

  useEffect(() => {
    if (!viewReadOnly && !isDateSelected) {
      register(
        { name: 'area_id' },
        {
          required: REQUIRED_STRING,
        },
      );
      register(
        { name: 'interval_id' },
        {
          required: REQUIRED_STRING,
        },
      );
    }
    if (!viewReadOnly && isDateSelected) {
      register(
        { name: 'full_address' },
        {
          required: REQUIRED_STRING,
        },
      );
      register(
        { name: 'order_status_id' },
        {
          required: REQUIRED_STRING,
        },
      );
    }
  }, []);

  useEffect(() => {
    if (currentOrder?.interval) {
      setIntervalPdf(currentOrder?.interval?.min?.concat(' - ')?.concat(currentOrder?.interval?.max));
    }
  }, [currentOrder?.interval]);

  useEffect(() => {
    const _currentOrder = currentOrder;
    _currentOrder.latitude = newLocation[0] ? newLocation[0] : null;
    _currentOrder.longitude = newLocation[1] ? newLocation[1] : null;
  }, [newLocation]);

  useEffect(() => {
    fetchItem({
      modelName: `/clientlist`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            res.push(response.data[i]);
          }
        }
        setClients(res);
      }
    });
    setPdfKey(pdfKey + 1);
    fetchItem({
      modelName: `/order-statuses`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const _final = [];
        for (let i = 0; i < response.data.length; i++) {
          if (String(response.data[i].title) !== 'Paid' && response.data[i].status === '1') {
            _final.push(response.data[i]);
          }
        }
        setOrderStatuses(_final);
      }
    });
    fetchItem({
      modelName: `/users`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res1 = [];
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].status === '1') {
            res1.push(response.data.data[i]);
          }
        }
        setUsers(res1);
      }
    });
    fetchItem({
      modelName: `/employee-routes`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const _data = [];
        for (let i = 0; i < response.data.data.length; i++) {
          _data.push(response.data.data[i].orders_id);
        }
        // const routes = _data.find((route) => route.includes(id));
        if (currentOrder.user_id) {
          if (permission === 'ADMIN') {
            const user = users.find((u) => String(u.id) === String(currentOrder.user_id));
            setRoutesOrder(
              user?.f_name
                ?.charAt('0')
                ?.toUpperCase()
                .concat(user?.l_name?.charAt('0')?.toUpperCase())
                .concat(String(currentOrder.orderIdList?.indexOf(id) + 1)),
            );
          }
          if (permission === 'EMPLOYEE') {
            setRoutesOrder(
              localStorage
                .getItem('f_name')
                ?.charAt('0')
                ?.toUpperCase()
                .concat(localStorage.getItem('l_name')?.charAt('0')?.toUpperCase())
                .concat(String(currentOrder.orderIdList?.indexOf(id) + 1)),
            );
          }
        }
      }
    });
    if (permission === 'ADMIN') {
      fetchItem({
        modelName: `/company-types`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const res = [];
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].status === '1') {
              res.push(response.data[i]);
            }
          }
          setCompanyTypes(res);
        }
      });
      fetchItem({
        modelName: `/job-types`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const res = [];
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].status === '1') {
              res.push(response.data[i]);
            }
          }
          setJobTypes(res);
        }
      });
      fetchItem({
        modelName: `/areas`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const res1 = [];
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].status === '1') {
              res1.push(response.data[i]);
            }
          }
          setAreas(res1);
        }
      });
      fetchItem({
        modelName: `/intervals`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          setIntervals(response.data);
          // const interval = response.data.find((int) => String(int.id) === String(currentOrder.interval_id));
          // if (interval) {
          //   setIntervalPdf(String(interval?.min).concat(' - ').concat(interval?.max));
          // }
        }
      });
      fetchItem({
        modelName: ``,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const res1 = [];
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].status === '1') {
              res1.push(response.data[i]);
            }
          }
          setZips(res1);
        }
      });
    }
  }, [permission, id]);

  useEffect(() => {
    if (users.length > 0 && currentOrder.id) {
      // fetchItem({
      //   modelName: `/technician-logs`,
      //   showLoader: true,
      // }).then((res1) => {
      //   const _updates = [];
      //   const list = res1.data;
      //   for (let i = 0; i < list.length; i++) {
      //     if (String(list[i].order_id) === String(currentOrder.id)) {
      //       const userName = users.find((x) => String(x.id) === String(list[i].user_id))?.f_name;
      //       const logActionName = list[i].log_action_id ? logActions[list[i].log_action_id] : list[i].message;
      //       const updateDate = list[i].date
      //         ? moment(list[i].date).format('MM/DD/YYYY, HH:MM:SS')
      //         : moment(list[i].created_at).format('MM/DD/YYYY, HH:MM:SS');
      //       _updates.push(
      //         <div>
      //           <span>{updateDate}</span>
      //           <span>{' '.concat(logActionName)?.concat(' ')}</span>
      //           <span>by {' '.concat(userName)}</span>
      //         </div>,
      //       );
      //     }
      //   }
      //   setUpdates(_updates);
      // });
      fetchItem({
        modelName: `/order-logs?order_id=${currentOrder.id}`,
        // modelName: `/order-logs`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const _updates = [];
          fetchItem({
            modelName: `/technician-logs`,
            showLoader: true,
          }).then((res1) => {
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].date = response.data[i].created_at;
            }
            const list = response.data.concat(res1.data).sort((a, b) => a.date - b.date);
            for (let i = 0; i < list.length; i++) {
              if (String(list[i].order_id) === String(currentOrder.id)) {
                const userName = users.find((x) => String(x.id) === String(list[i].user_id))?.f_name;
                const logActionName = list[i].log_action_id ? logActions[list[i].log_action_id] : list[i].message;
                const updateDate = moment(new Date(list[i].created_at)).format().slice(0, -6).replace('T', ' ');
                if (
                  !list[i].log_action_id ||
                  (String(list[i].log_action_id) !== '2' &&
                    String(list[i].log_action_id) !== '3' &&
                    String(list[i].log_action_id) !== '4' &&
                    String(list[i].log_action_id) !== '5' &&
                    String(list[i].log_action_id) !== '9')
                ) {
                  _updates.push(
                    <div>
                      <span>{updateDate}</span>
                      <span>{' '.concat(logActionName)?.concat(' ')}</span>
                      <span>by {' '.concat(userName)}</span>
                    </div>,
                  );
                }
              }
            }
            setUpdates(_updates);
          });
        }
      });
      fetchItem({
        modelName: `/order-payments`,
        showLoader: true,
      })
        .then((response) => {
          if (response) {
            const _updates = [];
            for (let i = 0; i < response.data?.length; i++) {
              if (String(response.data[i].order_id) === String(currentOrder.id)) {
                if (String(response.data[i].payment_type_id) === '1') {
                  _updates.push(
                    <div style={{ display: 'block' }}>
                      <span>Paid</span>
                      <span>{' $'.concat(response.data[i].amount)?.concat(' ')}</span>
                      <span>by cash</span>
                    </div>,
                  );
                }
                if (String(response.data[i].payment_type_id) === '2') {
                  _updates.push(
                    <div style={{ display: 'block' }}>
                      <span>Paid</span>
                      <span>{' $'.concat(response.data[i].amount)?.concat(' + 4% TF = $')}</span>
                      <span>{Number(response.data[i].amount) + Number(response.data[i].percent_amount)}by CC</span>
                    </div>,
                  );
                }
              }
            }
            setPaymentLogs(_updates);
          }
        })
        .finally(() => setPdfKey(pdfKey + 1));
    }
  }, [currentOrder.id, users]);
  const managePdf = async () => {
    setPdfContain(null);
    setOpenPdf(true);
    if (intervalPdf && pdfKey >= 2) {
      if (currentOrder.user_id) {
        fetchItem({
          modelName: `/employee-routes`,
          showLoader: true,
        }).then(async (response) => {
          if (response) {
            const _data = [];
            for (let i = 0; i < response.data.data.length; i++) {
              _data.push(response.data.data[i].orders_id);
            }
            // const routes = _data.find((route) => route.includes(id));
            let _routeOrder = null;
            if (permission === 'ADMIN') {
              const user = users.find((u) => String(u.id) === String(currentOrder.user_id));
              _routeOrder = user?.f_name
                ?.charAt('0')
                ?.toUpperCase()
                .concat(user?.l_name?.charAt('0')?.toUpperCase())
                .concat(String(currentOrder.orderIdList?.indexOf(id) + 1));
            }
            if (permission === 'EMPLOYEE') {
              _routeOrder = localStorage
                .getItem('f_name')
                ?.charAt('0')
                ?.toUpperCase()
                .concat(localStorage.getItem('l_name')?.charAt('0')?.toUpperCase())
                .concat(String(currentOrder.orderIdList?.indexOf(id) + 1));
            }
            if (routesOrder || _routeOrder) {
              if (openPdf) {
                const pdf1 = (
                  <PdfContainer
                    createDate={moment(currentOrder.created_at).format('MM/DD/YYYY')}
                    date={
                      moment(currentOrder.date).format('MM/DD/YYYY')
                        ? moment(currentOrder.date).format('MM/DD/YYYY')
                        : ''
                    }
                    orderNumber={currentOrder.order_number}
                    client={convertArrayToKeyedObject(clients, 'id', 'name')[currentOrder.client_id]}
                    company={convertArrayToKeyedObject(companyTypes, 'id', 'name')[currentOrder.company_type_id]}
                    jobType={currentOrder.order_type === 'insurance' ? assignedJobTypes : []}
                    address={currentOrder.full_address}
                    tel={
                      currentOrder?.phone_1?.length === 10
                        ? normalizeUsPhone(currentOrder?.phone_1)
                        : currentOrder?.phone_1
                    }
                    mobile={
                      // eslint-disable-next-line no-nested-ternary
                      currentOrder?.phone_2
                        ? currentOrder?.phone_2?.length === 10
                          ? normalizeUsPhone(currentOrder?.phone_2)
                          : currentOrder?.phone_2
                        : null
                    }
                    email={currentOrder.email}
                    description={currentOrder.description}
                    payment={paymentLogs}
                    external={currentOrder.external_order_number}
                    special={currentOrder.special_notes}
                    clientFirstName={currentOrder.customer_f_name}
                    clientLastName={currentOrder.customer_l_name}
                    technichianReport={
                      currentOrder.approved_by_admin || currentOrder.report ? currentOrder.technician_report : null
                    }
                    routesOrder={routesOrder || _routeOrder}
                    intervalPdf={intervalPdf}
                    after={currentOrder.approved_by_admin ? afterImages : null}
                    before={currentOrder.approved_by_admin ? beforeImages : null}
                    additional={currentOrder.approved_by_admin ? additionalImages : null}
                  />
                );
                const blob = await pdf(pdf1).toBlob();
                setPdfFile(blob);
                if (currentOrder.id) {
                  const pdfDl = (
                    <PDFDownloadLink
                      document={pdf1}
                      fileName='document.pdf'
                      style={{ border: '1px solid #ddd', padding: '10px', margin: '12px' }}
                    >
                      Download PDF
                    </PDFDownloadLink>
                  );
                  setPdfContain(pdfDl);
                }
              }
            }
          }
        });
      } else if (openPdf) {
        const pdf1 = (
          <PdfContainer
            createDate={moment(currentOrder.created_at).format('MM/DD/YYYY')}
            date={moment(currentOrder.date).format('MM/DD/YYYY') ? moment(currentOrder.date).format('MM/DD/YYYY') : ''}
            orderNumber={currentOrder.order_number}
            client={convertArrayToKeyedObject(clients, 'id', 'name')[currentOrder.client_id]}
            company={convertArrayToKeyedObject(companyTypes, 'id', 'name')[currentOrder.company_type_id]}
            jobType={currentOrder.order_type === 'insurance' ? assignedJobTypes : []}
            address={currentOrder.full_address}
            tel={currentOrder?.phone_1?.length === 10 ? normalizeUsPhone(currentOrder?.phone_1) : currentOrder?.phone_1}
            mobile={
              // eslint-disable-next-line no-nested-ternary
              currentOrder?.phone_2
                ? currentOrder?.phone_2?.length === 10
                  ? normalizeUsPhone(currentOrder?.phone_2)
                  : currentOrder?.phone_2
                : null
            }
            email={currentOrder.email}
            description={currentOrder.description}
            payment={paymentLogs}
            external={currentOrder.external_order_number}
            special={currentOrder.special_notes}
            clientFirstName={currentOrder.customer_f_name}
            clientLastName={currentOrder.customer_l_name}
            technichianReport={
              currentOrder.approved_by_admin || currentOrder.report ? currentOrder.technician_report : null
            }
            routesOrder={routesOrder}
            intervalPdf={intervalPdf}
            after={currentOrder.approved_by_admin && currentOrder.report ? afterImages : null}
            before={currentOrder.approved_by_admin && currentOrder.report ? beforeImages : null}
            additional={additionalImages}
          />
        );
        const blob = await pdf(pdf1).toBlob();
        setPdfFile(blob);
        const pdfDl = (
          <PDFDownloadLink
            document={pdf1}
            fileName='document.pdf'
            style={{ border: '1px solid #ddd', padding: '10px', margin: '12px' }}
          >
            Download PDF
          </PDFDownloadLink>
        );
        setPdfContain(pdfDl);
      }
    }
  };

  useEffect(() => {
    setOpenPdf(false);
    managePdf();
    return () => setOpenPdf(false);
  }, [
    clients,
    companyTypes,
    assignedJobTypes,
    paymentLogs,
    routesOrder,
    intervalPdf,
    /* afterImages,
    beforeImages,
    additionalImages, */
  ]);

  const fileSelectedHandlerAdditionalImagesLoad = async (e) => {
    setAdditionalImages([]);
    setDeletedImages([]);
    const _temp = [];
    const _deleted = [];
    for (let i = 0; i < e.length; i++) {
      if (e[i].status === '1') {
        const f = e[i];
        f.name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(e[i].image);
        const imgSrc = `${f.name}?w=164&h=164&fit=crop&auto=format`;
        _temp.push({ img: imgSrc });
      }
      if (e[i].status === '0') {
        const f = e[i];
        f.name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(e[i].image);
        const imgSrc = `${f.name}?w=164&h=164&fit=crop&auto=format`;
        _deleted.push({ img: imgSrc });
      }
    }
    setDeletedImages(_deleted);
    setAdditionalImages(_temp);
  };

  const fileSelectedHandlerBeforeImagesLoad = async (e) => {
    setBeforeImages([]);
    const _temp = [];
    const _deleted = [];
    for (let i = 0; i < e.length; i++) {
      if (e[i].status === '1') {
        const f = e[i];
        f.name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(e[i].image);
        const imgSrc = `${f.name}?w=164&h=164&fit=crop&auto=format`;
        _temp.push({ img: imgSrc });
      }
      if (e[i].status === '0') {
        const f = e[i];
        f.name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(e[i].image);
        const imgSrc = `${f.name}?w=164&h=164&fit=crop&auto=format`;
        _deleted.push({ img: imgSrc });
      }
    }
    setDeletedImagesBefore(_deleted);
    setBeforeImages(_temp);
  };

  const fileSelectedHandlerAfterImagesLoad = async (e) => {
    setAfterImages([]);
    const _temp = [];
    const _deleted = [];
    for (let i = 0; i < e.length; i++) {
      if (e[i].status === '1') {
        const f = e[i];
        f.name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(e[i].image);
        const imgSrc = `${f.name}?w=164&h=164&fit=crop&auto=format`;
        _temp.push({ img: imgSrc });
      }
      if (e[i].status === '0') {
        const f = e[i];
        f.name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(e[i].image);
        const imgSrc = `${f.name}?w=164&h=164&fit=crop&auto=format`;
        _deleted.push({ img: imgSrc });
      }
    }
    setDeletedImagesAfter(_deleted);
    setAfterImages(_temp);
  };

  useEffect(() => {
    if (currentOrder.data) {
      setNotChangedOrder(currentOrder.data);
    }
  }, [currentOrder.id]);

  useEffect(() => {
    if (currentOrder.data) {
      setCurrentOrder(currentOrder.data);
    }
    if (currentOrder.additional?.length > 0 || additionalImagesUpload?.length > 0) {
      fileSelectedHandlerAdditionalImagesLoad(additionalImagesUpload || currentOrder.additional);
    }
    if (currentOrder.before?.length > 0 || beforeImagesUpload?.length > 0) {
      fileSelectedHandlerBeforeImagesLoad(beforeImagesUpload || currentOrder.before);
    }
    if (currentOrder.after?.length > 0 || afterImagesUpload?.length > 0) {
      fileSelectedHandlerAfterImagesLoad(afterImagesUpload || currentOrder.after);
    }
    if (currentOrder) {
      setValues(setValue, currentOrder);
    }
    if (currentOrder.id) {
      setIsDateSelected(true);
      const _order = currentOrder;
      _order.date = moment(currentOrder.date).format('YYYY-MM-DD');
      if (currentOrder.approved_by_admin === '0') {
        _order.approved_by_admin = false;
      }
      if (currentOrder.approved_by_admin === '1') {
        _order.approved_by_admin = true;
      }
      if (currentOrder.report === '0') {
        _order.report = false;
      }
      if (currentOrder.report === '1') {
        _order.report = true;
      }
      if (currentOrder.reopen === '0') {
        _order.reopen = false;
      }
      if (currentOrder.reopen === '1') {
        _order.reopen = true;
      }
      if (currentOrder.status === '0') {
        _order.status = false;
      }
      if (currentOrder.status === '1') {
        _order.status = true;
      }
      if (currentOrder.prepaid === '0') {
        _order.prepaid = false;
      }
      if (currentOrder.prepaid === '1') {
        _order.prepaid = true;
      }
      setCurrentOrder(_order);
    }
    if (currentOrder.id && currentOrder.previous_orders) {
      setOrdersLinksData(currentOrder.previous_orders);
    }
    if (currentOrder.id && currentOrder.bonus?.id) {
      setBonusId(currentOrder.bonus?.id);
    }
  }, [currentOrder, additionalImagesUpload, beforeImagesUpload, afterImagesUpload]);

  useEffect(() => {
    if (currentOrder.id && currentOrder.job_types.length > 0) {
      const _temp = [];
      for (let i = 0; i < currentOrder.job_types.length; i++) {
        _temp.push(currentOrder.job_types[i].title);
      }
      setAssignedJobTypes(_temp);
    }
  }, [currentOrder.id, currentOrder.job_types]);

  useEffect(() => {
    setKey(key + 1);
  }, [currentOrder.date]);

  useEffect(() => {
    if (fetchedHistory && historyIdList.length !== 0) {
      setCurrentOrder(fetchedHistory);
    }
  }, [fetchedHistory]);

  useEffect(() => {
    const types = [];
    for (let i = 1; i < jobTypes.length; i++) {
      types.push(jobTypes[i].title);
    }
    setJobTypesLabels(types);
  }, [jobTypes]);
  useEffect(() => {
    if (currentOrder?.code?.length === 5) {
      const area_id = zips.find((x) => String(x.code) === String(currentOrder.code))?.area_id;
      const _order = { ...currentOrder };
      _order.area_id = area_id;
      setCurrentOrder(_order);
    }
  }, [currentOrder.code]);

  useEffect(() => {
    const _order = { ...currentOrder };
    _order.full_address = fullAdress;
    _order.latitude = searchLat;
    _order.longitude = searchLang;
    setCurrentOrder(_order);
  }, [fullAdress, searchLat, searchLang]);

  useEffect(() => {
    const defaultInterval = intervals.find((x) => String(x.min).includes('8') && String(x.max).includes('8'))?.id;
    if (!currentOrder.id && !currentOrder.interval_id) {
      const _currentOrder = { ...currentOrder };
      _currentOrder.interval_id = defaultInterval;
      setCurrentOrder(_currentOrder);
    }
  }, [intervals]);

  useEffect(() => {
    if (navigator.geolocation) {
      const location_timeout = setTimeout(() => {}, 10000);

      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(location_timeout);
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setUserLocation([lat, lng]);
        },
        () => {
          clearTimeout(location_timeout);
        },
      );
    }
  }, []);

  const onSubmit = () => {
    setLoading(true);
    const _order = { ...currentOrder };
    if (currentOrder.status) {
      _order.status = '1';
    } else {
      _order.status = '0';
    }
    if (currentOrder.approved_by_admin) {
      _order.approved_by_admin = '1';
    } else {
      _order.approved_by_admin = '0';
    }
    if (currentOrder.report) {
      _order.report = '1';
    } else {
      _order.report = '0';
    }
    if (currentOrder.prepaid) {
      _order.prepaid = '1';
    } else {
      _order.prepaid = '0';
    }
    if (currentOrder.reopen) {
      _order.reopen = '1';
    } else {
      _order.reopen = '0';
    }
    if (searchLang || searchLat) {
      _order.latitude = searchLat;
      _order.longitude = searchLang;
    }
    if (_order.order_type === 'private' && !_order.email) {
      BSwalShowError(['The email field is required.']);
      setLoading(false);
      return;
    }
    if (!_order.latitude || !_order.longitude) {
      BSwalShowError(['Please select the exact location of the address on the map.']);
      setLoading(false);
      return;
    }
    if (currentOrder.expenses_paid_by_employee) {
      _order.expenses_paid_by_employee = '1';
    } else {
      _order.expenses_paid_by_employee = '0';
    }
    _order.previous_order_id = [];
    let tempOrders = [];
    const _tempOrders = [];
    for (let i = 0; i < currentOrder?.previous_orders?.length; i++) {
      const temp = orders.filter(
        (x) =>
          String(x.order_number).toLocaleLowerCase() ===
          String(currentOrder.previous_orders[i].order_number).toLocaleLowerCase(),
      );
      if (temp[0]) {
        _tempOrders.push(temp);
      } else {
        BSwalShowError(['The Entered Order Number not found.']);
        setLoading(false);
        return;
      }
    }
    // eslint-disable-next-line prefer-destructuring
    tempOrders = _tempOrders;
    for (let i = 0; i < tempOrders?.length; i++) {
      if (tempOrders[i].length > 0) {
        for (let j = 0; j < tempOrders[i].length; j++) {
          _order.previous_order_id.push(tempOrders[i][j].id);
        }
      }
    }
    if (assignedJobTypes?.length === 0 && currentOrder.order_type === 'insurance') {
      BSwalShowError(['Please select the job type']);
      setLoading(false);
      return;
    }
    if (currentOrder.order_type === 'private') {
      _order.job_types = [];
      _order.job_types.push(1);
    }
    if (currentOrder.order_type === 'insurance' && permission === 'ADMIN') {
      _order.job_types = [];
      for (let i = 0; i < assignedJobTypes?.length; i++) {
        _order.job_types.push(jobTypes.find((x) => x.title === assignedJobTypes[i])?.id);
      }
    }
    if (currentOrder.order_type === 'insurance' && permission === 'EMPLOYEE') {
      const jobTypeIds = [];
      for (let i = 0; i < _order.job_types?.length; i++) {
        jobTypeIds.push(_order.job_types[i].id);
      }
      _order.job_types.length = 0;
      _order.job_types = jobTypeIds;
    }
    if (permission === 'EMPLOYEE') {
      _order.order_status_id = notChangedOrder.order_status_id;
      _order.order_status = notChangedOrder.order_status;
    }
    // if (beforeImagesSave.length > 0 || afterImagesSave.length > 0 || additionalImagesSave.length > 0) {
    //   setOpenDialog(true);
    //   setLoading(true);
    // }
    if (permission === 'ADMIN' && _order.approved_by_admin === '1' && _order.report === '1' && pdfFile) {
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
      const formData1 = new FormData();
      formData1.append('report_pdf', pdfFile);
      /* formData1.append(
        'customEmail',
        emailMsg.customEmail || currentOrder.order_type === 'insurance'
          ? currentOrder.client.email
          : currentOrder.email,
      );
      formData1.append('customMessage', emailMsg.customMessage);
      formData1.append('customSubject', emailMsg.customSubject); */
      axios({
        method: 'post',
        url: 'orders/upload-pdf/'.concat(id),
        data: formData1,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${cookies.get('userToken')}`,
        },
      })
        .then((res) => {
          if (res) {
            BSwalCustom('The mail has been sent!');
          }
          saveItem('orders', _order, id)
            .then((response) => {
              if (response.data.error) {
                BSwalShowError(response.data.error?.code);
              } else if (response.data.data[0] === 'there is not any payment for close this order!') {
                BSwalShowError(['There is no any payment done for this order.']);
              } else if (permission === 'ADMIN' && (bonusId || _order.bonus)) {
                const bonus = {
                  bonus_amount: _order.bonus?.bonus_amount ? _order.bonus?.bonus_amount : _order.bonus,
                  order_id: response?.data?.data?.id,
                  user_id: _order.user_id,
                };
                saveItem('order-bonuses', bonus, bonusId).then((r) => {
                  if (r.data.error) {
                    BSwalShowError(r.data.error?.code);
                  } else {
                    history.push('/order-management/orders');
                  }
                });
              }
            })
            .catch((err) => BSwalShowError(err?.data?.messages))
            .finally(async () => {
              if (id) {
                setPdfContain(null);
                const pdf1 = (
                  <PdfContainer
                    createDate={moment(currentOrder.created_at).format('MM/DD/YYYY')}
                    date={
                      moment(currentOrder.date).format('MM/DD/YYYY')
                        ? moment(currentOrder.date).format('MM/DD/YYYY')
                        : ''
                    }
                    orderNumber={currentOrder.order_number}
                    client={convertArrayToKeyedObject(clients, 'id', 'name')[currentOrder.client_id]}
                    company={convertArrayToKeyedObject(companyTypes, 'id', 'name')[currentOrder.company_type_id]}
                    jobType={currentOrder.order_type === 'insurance' ? assignedJobTypes : []}
                    address={currentOrder.full_address}
                    tel={
                      currentOrder?.phone_1?.length === 10
                        ? normalizeUsPhone(currentOrder?.phone_1)
                        : currentOrder?.phone_1
                    }
                    mobile={
                      // eslint-disable-next-line no-nested-ternary
                      currentOrder?.phone_2
                        ? currentOrder?.phone_2?.length === 10
                          ? normalizeUsPhone(currentOrder?.phone_2)
                          : currentOrder?.phone_2
                        : null
                    }
                    email={currentOrder.email}
                    description={currentOrder.description}
                    payment={paymentLogs}
                    external={currentOrder.external_order_number}
                    special={currentOrder.special_notes}
                    clientFirstName={currentOrder.customer_f_name}
                    clientLastName={currentOrder.customer_l_name}
                    technichianReport={currentOrder.approved_by_admin ? currentOrder.technician_report : null}
                    routesOrder={routesOrder}
                    intervalPdf={intervalPdf}
                    after={currentOrder.approved_by_admin ? afterImages : null}
                    before={currentOrder.approved_by_admin ? beforeImages : null}
                    additional={currentOrder.approved_by_admin ? additionalImages : null}
                  />
                );
                const blob = await pdf(pdf1).toBlob();
                setPdfFile(blob);
                const pdfDl = (
                  <PDFDownloadLink
                    document={pdf1}
                    fileName='document.pdf'
                    style={{ border: '1px solid #ddd', padding: '10px', margin: '12px' }}
                  >
                    Download PDF
                  </PDFDownloadLink>
                );
                setPdfContain(pdfDl);
              }
              setLoading(false);
              if (!id) {
                history.push('/order-management/orders');
              }
            });
        })
        .catch((err) => {
          BSwalShowError([err.response.data?.message]);
          setLoading(false);
        });
    } else {
      saveItem('orders', _order, id)
        .then((response) => {
          if (response.data.error) {
            if (response.data.error?.code) {
              BSwalShowError(response.data.error?.code);
            }
            try {
              // eslint-disable-next-line no-restricted-syntax
              for (const [, value] of Object.entries(response.data.error)) {
                BSwalShowError(value);
              }
            } catch {
              BSwalShowError(['Error in saving the order.']);
            }
          } else if (response.data.data[0] === 'there is not any payment for close this order!') {
            BSwalShowError(['There is no any payment done for this order.']);
          } else {
            axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
            const formData2 = new FormData();
            formData2.append('report_route_pdf', pdfFile);
            const pdfSave = axios({
              method: 'post',
              url: 'orders/upload-route-pdf/'.concat(response.data.data.id),
              data: formData2,
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${cookies.get('userToken')}`,
              },
            });
            pdfSave.catch(() => BSwalShowError(['Error in saving the pdf file.'])).finally(() => setLoading(false));
            if (permission === 'EMPLOYEE' && response.data.data) {
              setCurrentOrder(response.data.data);
            }
            if (permission === 'EMPLOYEE') {
              if (response.data.data) {
                setCurrentOrder(response.data.data);
              }
            }
            if (!id) {
              history.push(`/order-management/order/${response.data.data.id}`);
            }
          }
        })
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(async () => {
          if (id) {
            setPdfContain(null);
            const pdf1 = (
              <PdfContainer
                createDate={moment(currentOrder.created_at).format('MM/DD/YYYY')}
                date={
                  moment(currentOrder.date).format('MM/DD/YYYY') ? moment(currentOrder.date).format('MM/DD/YYYY') : ''
                }
                orderNumber={currentOrder.order_number}
                client={convertArrayToKeyedObject(clients, 'id', 'name')[currentOrder.client_id]}
                company={convertArrayToKeyedObject(companyTypes, 'id', 'name')[currentOrder.company_type_id]}
                jobType={currentOrder.order_type === 'insurance' ? assignedJobTypes : []}
                address={currentOrder.full_address}
                tel={
                  currentOrder?.phone_1?.length === 10 ? normalizeUsPhone(currentOrder?.phone_1) : currentOrder?.phone_1
                }
                mobile={
                  // eslint-disable-next-line no-nested-ternary
                  currentOrder?.phone_2
                    ? currentOrder?.phone_2?.length === 10
                      ? normalizeUsPhone(currentOrder?.phone_2)
                      : currentOrder?.phone_2
                    : null
                }
                email={currentOrder.email}
                description={currentOrder.description}
                payment={paymentLogs}
                external={currentOrder.external_order_number}
                special={currentOrder.special_notes}
                clientFirstName={currentOrder.customer_f_name}
                clientLastName={currentOrder.customer_l_name}
                technichianReport={currentOrder.approved_by_admin ? currentOrder.technician_report : null}
                routesOrder={routesOrder}
                intervalPdf={intervalPdf}
                after={currentOrder.approved_by_admin ? afterImages : null}
                before={currentOrder.approved_by_admin ? beforeImages : null}
                additional={currentOrder.approved_by_admin ? additionalImages : null}
              />
            );
            const blob = await pdf(pdf1).toBlob();
            setPdfFile(blob);
            const pdfDl = (
              <PDFDownloadLink
                document={pdf1}
                fileName='document.pdf'
                style={{ border: '1px solid #ddd', padding: '10px', margin: '12px' }}
              >
                Download PDF
              </PDFDownloadLink>
            );
            setPdfContain(pdfDl);
          }
          setLoading(false);
        });
    }
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentOrder, setCurrentOrder);
  };

  const fileSelectedHandler = async (e) => {
    setLoading(true);
    if (e.target.files.length <= 10) {
      const imageSet = [...beforeImages];
      const _arr = [];
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const formData = new FormData();
        formData.append('before', file);
        axios({
          method: 'post',
          url: 'orders/add-image/'.concat(currentOrder.id),
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${cookies.get('userToken')}`,
          },
          onUploadProgress: (data) => {
            setUploadedBefore(Math.round((data.loaded / data.total) * 100));
          },
        })
          .then((response) => {
            const { data } = response.data;
            if (data) {
              setUploadedBefore(0);
              setBeforeImagesUpload(data.before);
              setBeforeImages(imageSet);
            }
          })
          .catch(() => {
            setUploadedBefore(0);
            BSwalShowError(['Error in uploading images.']);
          });
        // eslint-disable-next-line no-shadow
        setBeforeImagesSave((beforeImagesSave) => [...beforeImagesSave, file]);
        _arr.push(e.target.files[i]);
      }
      if (_arr.length > 0) {
        const filePromises = _arr.map(
          (file) =>
            // Return a promise per file
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = async () => {
                try {
                  const response = { img: reader.result };
                  // Resolve the promise with the response value
                  resolve(response);
                } catch (err) {
                  reject(err);
                }
              };
              reader.onerror = (error) => {
                reject(error);
              };
              reader.readAsDataURL(file);
            }),
        );
        // Wait for all promises to be resolved
        const fileInfos = await Promise.all(filePromises);
        for (let i = 0; i < fileInfos.length; i++) {
          imageSet.push(fileInfos[i]);
        }
      }
      setLoading(false);
    } else {
      BSwalShowError(['Only 10 files are allowed to upload']);
      setLoading(false);
    }
  };

  const fileSelectedHandlerAfterImages = async (e) => {
    setLoading(true);
    if (e.target.files.length <= 10) {
      const imageSet = [...afterImages];
      const _arr = [];
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const formData = new FormData();
        formData.append('after', file);
        axios({
          method: 'post',
          url: 'orders/add-image/'.concat(currentOrder.id),
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${cookies.get('userToken')}`,
          },
          onUploadProgress: (data) => {
            setUploadedAfter(Math.round((data.loaded / data.total) * 100));
          },
        })
          .then((response) => {
            const { data } = response.data;
            if (data) {
              setUploadedAfter(0);
              setAfterImagesUpload(data.after);
              setAfterImages(imageSet);
            }
          })
          .catch(() => {
            setUploadedAfter(0);
            BSwalShowError(['Error in uploading images.']);
          });
        // eslint-disable-next-line no-shadow
        setAfterImagesSave((afterImagesSave) => [...afterImagesSave, file]);
        _arr.push(e.target.files[i]);
      }
      if (_arr.length > 0) {
        const filePromises = _arr.map(
          (file) =>
            // Return a promise per file
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = async () => {
                try {
                  const response = { img: reader.result };
                  // Resolve the promise with the response value
                  resolve(response);
                } catch (err) {
                  reject(err);
                }
              };
              reader.onerror = (error) => {
                reject(error);
              };
              reader.readAsDataURL(file);
            }),
        );
        // Wait for all promises to be resolved
        const fileInfos = await Promise.all(filePromises);
        for (let i = 0; i < fileInfos.length; i++) {
          imageSet.push(fileInfos[i]);
        }
      }
      setLoading(false);
    } else {
      BSwalShowError(['Only 10 files are allowed to upload']);
      setLoading(false);
    }
  };

  const fileSelectedHandlerAdditionalImages = async (e) => {
    setLoading(true);
    if (e.target.files.length <= 10) {
      const imageSet = [...additionalImages];
      const _arr = [];
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const formData = new FormData();
        formData.append('additional', file);
        axios({
          method: 'post',
          url: 'orders/add-image/'.concat(currentOrder.id),
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${cookies.get('userToken')}`,
          },
          onUploadProgress: (data) => {
            setUploadedAdditional(Math.round((data.loaded / data.total) * 100));
          },
        })
          .then((response) => {
            const { data } = response.data;
            if (data) {
              setUploadedAdditional(0);
              setAdditionalImages(imageSet);
              setCalendarOrders(data);
              setAdditionalImagesUpload(data.additional);
            }
          })
          .catch(() => {
            setUploadedAdditional(0);
            BSwalShowError(['Error in uploading images.']);
          });
        // eslint-disable-next-line no-shadow
        setAdditionalImagesSave((additionalImagesSave) => [...additionalImagesSave, file]);
        _arr.push(e.target.files[i]);
      }
      if (_arr.length > 0) {
        const filePromises1 = _arr.map(
          (file) =>
            new Promise((resolve) => {
              loadImage(
                file,
                // eslint-disable-next-line no-loop-func
                (img, data) => {
                  if (data.imageHead && data.exif) {
                    // eslint-disable-next-line no-undef
                    loadImage.writeExifData(data.imageHead, data, 'Orientation', 1);
                    img.toBlob((blob) => {
                      // eslint-disable-next-line no-undef
                      loadImage.replaceHead(blob, data.imageHead, (newBlob) => {
                        // eslint-disable-next-line no-undef,no-param-reassign
                        file = newBlob;
                        resolve('done');
                      });
                    }, 'image/jpeg');
                  } else {
                    resolve('done');
                  }
                },
                { meta: true, orientation: true, canvas: true, maxWidth: 800, compressImageQuality: 0.99 },
              );
            }),
        );
        // Wait for all promises to be resolved
        const fileInfoss = await Promise.all(filePromises1);
        if (fileInfoss.includes('done')) {
          const filePromises = _arr.map(
            (file) =>
              // Wait for all promises to be resolved
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = async () => {
                  try {
                    const response = { img: reader.result };
                    // Resolve the promise with the response value
                    resolve(response);
                  } catch (err) {
                    reject(err);
                  }
                };
                reader.onerror = (error) => {
                  reject(error);
                };
                reader.readAsDataURL(file);
              }),
          );
          const fileInfos = await Promise.all(filePromises);
          for (let i = 0; i < fileInfos.length; i++) {
            imageSet.push(fileInfos[i]);
          }
        }
      }
      setLoading(false);
    } else {
      BSwalShowError(['Only 10 files are allowed to upload']);
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const options = event.target.value;
    const value = [];
    for (let i = 0, l = options?.length; i < l; i += 1) {
      value.push(options[i]);
    }
    setAssignedJobTypes(value);
  };

  const addPreviousOrderId = (item) => {
    const _orderNumbers = [];
    const finalItem = [];
    for (let i = 0; i < currentOrder.previous_orders.length; i++) {
      _orderNumbers.push(currentOrder.previous_orders[i].order_number);
    }
    if (item.length === 0) {
      onChange(onChangeTable('previous_orders', item));
    }
    for (let i = 0; i < item.length; i++) {
      const temp = orders.filter(
        (x) => String(x.order_number).toLocaleLowerCase() === String(item[i].order_number).toLocaleLowerCase(),
      );
      if (temp[0]) {
        if (String(temp[0].id) === String(currentOrder.id)) {
          BSwalShowError(['You have entered the current order number.']);
        } else {
          // eslint-disable-next-line no-lonely-if
          if (
            _orderNumbers.includes(item[i].order_number) &&
            i === item.length - 1 &&
            currentOrder.previous_orders.length <= item.length
          ) {
            BSwalShowError(['You have already entered the order number.']);
            break;
          } else {
            finalItem.push(item[i]);
          }
        }
      } else {
        BSwalShowError(['The Entered Order Number not found.']);
        return;
      }
      onChange(onChangeTable('previous_orders', finalItem));
    }
  };

  const onSelectCalendar = (value) => {
    setCalendarSelectedValue(value);
    setCalendarValue(value);
  };

  const onPanelChangeCalendar = (value) => {
    setCalendarValue(value);
  };

  const confirmDate = () => {
    const _order = currentOrder;
    _order.date = moment(calendarValue).format('YYYY-MM-DD');
    setCurrentOrder(_order);
    setIsDateSelected(true);
  };
  const searchOrder = () => {
    setLoading(true);
    fetchItem({
      modelName: `/orders?area_id=${currentOrder.area_id}&interval_id=${currentOrder.interval_id}&from_date=${moment(
        calendarValue,
      )
        .startOf('month')
        .format('MM-DD-YYYY')}&to_date=${moment(calendarValue).endOf('month').format('MM-DD-YYYY')}&sort=asc`,
      showLoader: true,
    })
      .then((response) => {
        const { data } = response.data;
        if (data) {
          setCalendarOrders(data);
        }
      })
      .finally(() => setLoading(false));
  };
  const historyModalVisible = () => openDialog;
  const historyDialog = (
    <LoadingDialog
      open={historyModalVisible}
      handleClose={() => {
        setOpenDialog(false);
      }}
      maxWidth='xl'
    />
  );

  const dateCellRender = (value) => {
    let listData = [];
    if (calendarOrders.filter((x) => moment(x.date).format('MM-DD-YYYY') === moment(value).format('MM-DD-YYYY'))) {
      listData = calendarOrders.filter(
        (x) => moment(x.date).format('MM-DD-YYYY') === moment(value).format('MM-DD-YYYY'),
      );
    }
    return (
      <>
        {listData.length > 0 && listData[0]?.id && (
          <ul className='events'>
            {listData.map((item) => (
              <li key={item?.id}>
                <Badge status='success' text={item?.order_number} />
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };
  const dateForm = (
    <Card>
      <form className='formContainer' onSubmit={handleSubmit(searchOrder)}>
        {/* <CardHeader title='Select Date' />
        <Divider /> */}
        <CardContent>
          <Grid container spacing={3}>
            <BTextField
              sm={4}
              xs={12}
              label='Zip'
              name='code'
              value={currentOrder.code}
              onChange={onChange}
              error={errors.code}
              readOnly={viewReadOnly}
            />
            <BSelectField
              sm={4}
              xs={12}
              label='Select Area'
              required
              name='area_id'
              value={currentOrder.area_id}
              onChange={onChange}
              error={errors.area_id}
              items={convertArrayToKeyedObject(areas, 'id', 'title')}
              readOnly={viewReadOnly}
            />
            <BSelectField
              sm={4}
              xs={12}
              label='Select Hours Intervals'
              required
              name='interval_id'
              value={currentOrder.interval_id}
              onChange={onChange}
              error={errors.interval_id}
              items={convertArrayToKeyedObjects(intervals, 'id', 'min', 'max', '-')}
              readOnly={viewReadOnly}
            />
            <Grid item xs={12} sm={3} style={{ marginTop: '50px' }}>
              <BSubmitButton id='searchOrder' label='Search' loading={loading} />
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );

  const setAddress = (address) => {
    setFullAddress(address?.geocode?.name);
    setSearchLat(address?.geocode?.center?.lat);
    setSearchLang(address?.geocode?.center?.lng);
  };

  const openMap = () => {
    if (viewReadOnly && currentOrder.latitude) {
      const newWindow = window.open(
        `https://www.google.com/maps/search/?api=1&query=${currentOrder.latitude},${currentOrder.longitude}`,
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const newOrderEmployee = () => {
    localStorage.setItem('currentOrder', currentOrder.id);
    history.push(`/employees/new-order`);
  };

  const newOrderAdmin = () => {
    localStorage.setItem('currentOrder', currentOrder.id);
    history.push(`/admin/new-order`);
  };

  const onRowClick = (event, rowData) => {
    if (permission === 'ADMIN') {
      history.push(`/order-management/orderLink/${rowData.id}`);
    }
    if (permission === 'EMPLOYEE') {
      history.push(`/employees/orderLink/${rowData.id}`);
    }
  };
  const openPayment = () => {
    if (permission === 'ADMIN') {
      history.push(`/orders-payments/${currentOrder.id}`);
    }
    if (permission === 'EMPLOYEE') {
      history.push(`/order-payment/${currentOrder.id}`);
    }
  };
  const removedAdditionalImage = () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios
      .get(`/orders/${currentOrder.id}`, {
        headers: {
          Authorization: `Bearer ${cookies.get('userToken')}`,
        },
      })
      .then((res) => {
        if (res) {
          // console.log(res.data);
        }
      });
  };

  const removedAfterlImage = () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios
      .get(`/orders/${currentOrder.id}`, {
        headers: {
          Authorization: `Bearer ${cookies.get('userToken')}`,
        },
      })
      .then((res) => {
        if (res) {
          // console.log(res.data);
        }
      });
  };

  const removedBeforelImage = () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios
      .get(`/orders/${currentOrder.id}`, {
        headers: {
          Authorization: `Bearer ${cookies.get('userToken')}`,
        },
      })
      .then((res) => {
        if (res) {
          // console.log(res.data);
        }
      });
  };

  const removeImgAdditional = (imgIndex) => {
    const _selected = [...additionalImages];
    if (currentOrder?.additional?.length > 0 || additionalImagesUpload?.length > 0) {
      const additionalItem =
        additionalImagesUpload?.length > 0
          ? additionalImagesUpload?.find((item) => additionalImages[imgIndex]?.img.includes(item?.image))
          : currentOrder?.additional?.find((item) => additionalImages[imgIndex]?.img.includes(item?.image));
      if (additionalItem) {
        setLoading(true);
        removeItem('orders/delete-image', additionalItem?.id)
          .then(() => {
            removedAdditionalImage();
            const name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(additionalItem.image);
            const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
            deletedImages.push({ img: imgSrc });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    _selected.splice(imgIndex, 1);
    setAdditionalImages(_selected);
    const _saved = [...additionalImagesSave];
    _saved.splice(imgIndex, 1);
    setAdditionalImagesSave(_saved);
  };
  const removeImgAfter = (imgIndex) => {
    const _selected = [...afterImages];
    if (currentOrder?.after?.length > 0 || afterImagesUpload?.length > 0) {
      const afterItem =
        afterImagesUpload?.length > 0
          ? afterImagesUpload?.find((item) => afterImages[imgIndex]?.img.includes(item?.image))
          : currentOrder?.after?.find((item) => afterImages[imgIndex]?.img.includes(item?.image));

      if (afterItem) {
        setLoading(true);
        removeItem('orders/delete-image', afterItem?.id)
          .then(() => {
            const name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(afterItem.image);
            const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
            deletedImagesAfter.push({ img: imgSrc });
            removedAfterlImage();
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    _selected.splice(imgIndex, 1);
    setAfterImages(_selected);
    const _saved = [...afterImagesSave];
    _saved.splice(imgIndex, 1);
    setAfterImagesSave(_saved);
  };
  const removeImgBefore = (imgIndex) => {
    const _selected = [...beforeImages];
    if (currentOrder?.before?.length > 0 || beforeImagesUpload?.length > 0) {
      const beforeItem =
        beforeImagesUpload?.length > 0
          ? beforeImagesUpload?.find((item) => beforeImages[imgIndex]?.img.includes(item?.image))
          : currentOrder?.before?.find((item) => beforeImages[imgIndex]?.img.includes(item?.image));

      if (beforeItem) {
        setLoading(true);
        removeItem('orders/delete-image', beforeItem?.id)
          .then(() => {
            const name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(beforeItem.image);
            const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
            deletedImagesBefore.push({ img: imgSrc });
            removedBeforelImage();
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    _selected.splice(imgIndex, 1);
    setBeforeImages(_selected);
    const _saved = [...beforeImagesSave];
    _saved.splice(imgIndex, 1);
    setBeforeImagesSave(_saved);
  };
  const updateChatList = () => {
    setKey(key + 1);
  };
  const closeOrder = () => {
    const _order = { ...currentOrder };
    _order.reopen = '0';
    if (currentOrder.status) {
      _order.status = '1';
    } else {
      _order.status = '0';
    }
    if (currentOrder.approved_by_admin) {
      _order.approved_by_admin = '1';
    } else {
      _order.approved_by_admin = '0';
    }
    if (currentOrder.report) {
      _order.report = '1';
    } else {
      _order.report = '0';
    }
    if (currentOrder.prepaid) {
      _order.prepaid = '1';
    } else {
      _order.prepaid = '0';
    }
    if (searchLang || searchLat) {
      _order.latitude = searchLat;
      _order.longitude = searchLang;
    }
    if (!_order.latitude || !_order.longitude) {
      BSwalShowError(['Please select the exact location of the address on the map.']);
      setLoading(false);
      return;
    }
    if (currentOrder.expenses_paid_by_employee) {
      _order.expenses_paid_by_employee = '1';
    } else {
      _order.expenses_paid_by_employee = '0';
    }
    _order.previous_order_id = [];
    let tempOrders = [];
    const _tempOrders = [];
    for (let i = 0; i < currentOrder?.previous_orders?.length; i++) {
      const temp = orders.filter(
        (x) =>
          String(x.order_number).toLocaleLowerCase() ===
          String(currentOrder.previous_orders[i].order_number).toLocaleLowerCase(),
      );
      if (temp[0]) {
        _tempOrders.push(temp);
      } else {
        BSwalShowError(['The Entered Order Number not found.']);
        setLoading(false);
        return;
      }
    }
    // eslint-disable-next-line prefer-destructuring
    tempOrders = _tempOrders;
    for (let i = 0; i < tempOrders?.length; i++) {
      if (tempOrders[i].length > 0) {
        for (let j = 0; j < tempOrders[i].length; j++) {
          _order.previous_order_id.push(tempOrders[i][j].id);
        }
      }
    }
    if (assignedJobTypes?.length === 0 && currentOrder.order_type === 'insurance') {
      BSwalShowError(['Please select the job type']);
      setLoading(false);
      return;
    }
    if (currentOrder.order_type === 'private') {
      _order.job_types = [];
      _order.job_types.push(1);
    }
    if (currentOrder.order_type === 'insurance' && permission === 'ADMIN') {
      _order.job_types = [];
      for (let i = 0; i < assignedJobTypes?.length; i++) {
        _order.job_types.push(jobTypes.find((x) => x.title === assignedJobTypes[i])?.id);
      }
    }
    if (currentOrder.order_type === 'insurance' && permission === 'EMPLOYEE') {
      const jobTypeIds = [];
      for (let i = 0; i < _order.job_types?.length; i++) {
        jobTypeIds.push(_order.job_types[i].id);
      }
      _order.job_types.length = 0;
      _order.job_types = jobTypeIds;
    }
    _order.order_status = currentOrder.order_status;
    _order.order_status_id = currentOrder.order_status_id;
    // if (beforeImagesSave.length > 0 || afterImagesSave.length > 0 || additionalImagesSave.length > 0) {
    //   setOpenDialog(true);
    //   setLoading(true);
    // }
    saveItem('orders', _order, id)
      .then((response) => {
        if (response.data.error) {
          BSwalShowError(response.data.error?.code);
        } else if (response.data.data[0] === 'there is not any payment for close this order!') {
          BSwalShowError(['There is no any payment done for this order.']);
        } else if (permission === 'ADMIN') {
          history.push('/order-management/orders');
        } else {
          axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
          // if (beforeImagesSave.length > 0 || afterImagesSave.length > 0 || additionalImagesSave.length > 0) {
          //   const promises = [];
          //   for (let i = 0; i < beforeImagesSave.length; i++) {
          //     const formData = new FormData();
          //     formData.append('before', beforeImagesSave[i]);
          //     promises.push(
          //       axios({
          //         method: 'post',
          //         url: 'orders/add-image/'.concat(response?.data?.data?.id),
          //         data: formData,
          //         headers: {
          //           'Content-Type': 'multipart/form-data',
          //           Authorization: `Bearer ${cookies.get('userToken')}`,
          //         },
          //       }),
          //     );
          //   }
          //   for (let i = 0; i < afterImagesSave.length; i++) {
          //     const formData = new FormData();
          //     formData.append('after', afterImagesSave[i]);
          //     promises.push(
          //       axios({
          //         method: 'post',
          //         url: 'orders/add-image/'.concat(response?.data?.data?.id),
          //         data: formData,
          //         headers: {
          //           'Content-Type': 'multipart/form-data',
          //           Authorization: `Bearer ${cookies.get('userToken')}`,
          //         },
          //       }),
          //     );
          //   }
          //   for (let i = 0; i < additionalImagesSave.length; i++) {
          //     const formData = new FormData();
          //     formData.append('additional', additionalImagesSave[i]);
          //     promises.push(
          //       axios({
          //         method: 'post',
          //         url: 'orders/add-image/'.concat(response?.data?.data?.id),
          //         data: formData,
          //         headers: {
          //           'Content-Type': 'multipart/form-data',
          //           Authorization: `Bearer ${cookies.get('userToken')}`,
          //         },
          //       }),
          //     );
          //   }
          //   Promise.allSettled(promises)
          //     .then(() => {
          //       setBeforeImagesSave([]);
          //       setAfterImagesSave([]);
          //       setAdditionalImagesSave([]);
          //       setLoading(false);
          //       setOpenDialog(false);
          //     })
          //     .catch(() => {
          //       setLoading(false);
          //       setOpenDialog(false);
          //       BSwalShowError(['Error in uploading images.']);
          //     });
          // }
        }
      })
      .finally(() => {
        setLoading(false);
        history.push('/employees/orders');
      });
  };

  const saveTechnician = () => {
    const logs = {
      order_id: id,
      user_id: permission === 'ADMIN' ? localStorage.getItem('uid') : currentOrder.user_id,
      message: techMsg,
      date: moment(),
    };
    saveItem('/technician-logs', logs, null).then((res) => {
      setTechMsg(null);
      fetchItem({
        modelName: `/technician-logs`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const _updates = [...updates];
          for (let i = 0; i < response.data?.length; i++) {
            if (String(response.data[i].id) === String(res?.data?.data?.id)) {
              const userName = users.find((x) => String(x.id) === String(response.data[i].user_id))?.f_name;
              const logActionName = response.data[i].message;
              const updateDate = moment(new Date(response.data[i].created_at)).format().slice(0, -6).replace('T', ' ');
              _updates.push(
                <div>
                  <span>{updateDate}</span>
                  <span>{' '.concat(logActionName)?.concat(' ')}</span>
                  <span>by {' '.concat(userName)}</span>
                </div>,
              );
            }
          }
          setUpdates(_updates);
        }
      });
    });
  };

  return (
    <div style={{ paddingBottom: '40px' }}>
      {!isDateSelected && (
        <>
          {dateForm}
          <Alert
            message={`You selected date: ${calendarSelectedValue && calendarSelectedValue.format('MM-DD-YYYY')}`}
          />
          <Calendar
            disabledDate={(date) => {
              if (date.endOf('d').valueOf() < moment()) {
                return true;
              }
              return false;
            }}
            value={calendarValue}
            onSelect={onSelectCalendar}
            onPanelChange={onPanelChangeCalendar}
            dateCellRender={dateCellRender}
          />
          <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button
              id='confirm'
              onClick={confirmDate}
              color='secondary'
              variant='outlined'
              disabled={!currentOrder.area_id || !currentOrder.interval_id}
            >
              Confirm and start creating the order
            </Button>
          </Grid>
        </>
      )}
      {currentOrder && isDateSelected && historyDialog}
      {currentOrder && isDateSelected && (
        <Card>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <div style={{ textAlign: 'center', borderBottom: '1px solid #ddd', padding: '5px' }}>
              <h1 style={{ marginBottom: '0px' }}>{currentOrder.order_number}</h1>
              <h3>{currentOrder.external_order_number}</h3>
              {currentOrder.created_from_order && <div>Created from Order: {currentOrder.created_from_order}</div>}
            </div>
            <Divider />
            {currentOrder.id && currentOrder.order_type === 'private' && (
              <Button
                id='pay'
                onClick={openPayment}
                color='secondary'
                variant='outlined'
                style={{ float: 'right', marginTop: '10px' }}
              >
                <MonetizationOnIcon />
              </Button>
            )}
            <Button
              id='backToListIcon'
              component={Link}
              to={{
                pathname: permission === 'ADMIN' ? '/order-management/orders' : '/employees/orders',
              }}
              color='secondary'
              variant='outlined'
              style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}
            >
              <ArrowBackIcon />
            </Button>
            {permission === 'ADMIN' && (
              <BCheckBoxField
                sm={4}
                name='status'
                label='Enabled status'
                value={Number(currentOrder?.status)}
                error={errors.status}
                onChange={onChange}
                readOnly={viewReadOnly}
                style={{ marginTop: '40px' }}
              />
            )}
            <CardContent>
              <br />
              {historyInfo}
              {permission === 'ADMIN' && (
                <Grid container spacing={3}>
                  <h2 style={{ paddingLeft: '10px' }}>Order Details</h2>
                  <Grid item sm={12} xs={12} style={{ marginTop: '20px' }}>
                    <FormControl component='fieldset' style={{ width: '100%' }} disabled={!!currentOrder.id}>
                      <FormLabel component='legend'>Order Type</FormLabel>
                      <RadioGroup
                        aria-label='Order Type'
                        name='order_type'
                        value={currentOrder.order_type}
                        onChange={onChange}
                        style={{ display: 'inline' }}
                      >
                        <FormControlLabel value='private' control={<Radio />} label='Private' />
                        <FormControlLabel value='insurance' control={<Radio />} label='Insurance' />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <BSelectField
                    sm={4}
                    xs={12}
                    label='Area'
                    required
                    name='area_id'
                    value={currentOrder.area_id}
                    onChange={onChange}
                    error={errors.area_id}
                    items={convertArrayToKeyedObject(areas, 'id', 'title')}
                    readOnly={viewReadOnly || !currentOrder.id}
                  />
                  <BSelectField
                    sm={4}
                    xs={12}
                    label='Hours Intervals'
                    required
                    name='interval_id'
                    value={currentOrder.interval_id}
                    onChange={onChange}
                    error={errors.interval_id}
                    items={convertArrayToKeyedObjects(intervals, 'id', 'min', 'max', '-')}
                    readOnly={viewReadOnly || !currentOrder.id}
                  />
                  {currentOrder.id && (
                    <BDateFieldTable
                      name='createddate'
                      label='Order Created'
                      value={currentOrder?.created_at}
                      readOnly
                    />
                  )}
                  <BDateFieldTable
                    key={key}
                    name='date'
                    label='Route Date'
                    error={errors.date}
                    value={currentOrder?.date}
                    onChange={onChange}
                    readOnly={viewReadOnly || !currentOrder.id}
                    required
                  />
                  <BTextField
                    label='Order Number'
                    name='order_number'
                    value={currentOrder?.order_number}
                    onChange={onChange}
                    readOnly
                    onKeyDown={onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers}
                    error={errors.order_number}
                  />
                  {currentOrder.order_type === 'insurance' && (
                    <BTextField
                      label='External Order Number'
                      name='external_order_number'
                      value={currentOrder?.external_order_number}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                      error={errors.external_order_number}
                    />
                  )}
                  {currentOrder.order_type === 'insurance' && (
                    <BTextField
                      label='Claim Number'
                      name='claim_number'
                      value={currentOrder?.claim_number}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                      onKeyDown={onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers}
                      error={errors.claim_number}
                    />
                  )}
                  {currentOrder.order_type === 'insurance' && (
                    <BTextField
                      label='Invoice Number'
                      name='invoice_number'
                      value={currentOrder?.invoice_number}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                      onKeyDown={onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers}
                      error={errors.invoice_number}
                    />
                  )}
                  {currentOrder.id && (
                    <BTextField
                      sm={4}
                      xs={12}
                      label='Created By'
                      name='created_by'
                      value={currentOrder.created_by?.f_name?.concat(' ').concat(currentOrder.created_by?.l_name)}
                      onChange={onChange}
                      error={errors.created_by}
                      readOnly
                    />
                  )}
                  {currentOrder.order_type === 'insurance' && (
                    <BSelectField
                      sm={4}
                      xs={12}
                      label='Company Type'
                      required
                      name='company_type_id'
                      value={currentOrder.company_type_id}
                      onChange={onChange}
                      error={errors.company_type_id}
                      items={convertArrayToKeyedObject(companyTypes, 'id', 'name')}
                      readOnly={viewReadOnly}
                    />
                  )}
                  <BTextField
                    label='Travel Price'
                    name='travel_price'
                    value={currentOrder?.travel_price}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                    error={errors.travel_price}
                  />
                  <BTextField
                    label='Bonus'
                    sm={4}
                    name='bonus'
                    value={currentOrder?.bonus?.id ? currentOrder?.bonus?.bonus_amount : currentOrder?.bonus}
                    onChange={onChange}
                    error={errors.bonus}
                    onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                    readOnly={viewReadOnly}
                  />
                  {currentOrder.order_type === 'private' && (
                    <>
                      <BTextField
                        label='Expenses'
                        sm={4}
                        name='expenses'
                        value={currentOrder?.expenses}
                        onChange={onChange}
                        error={errors.expenses}
                        onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                        readOnly={viewReadOnly}
                      />
                      <BCheckBoxField
                        sm={4}
                        name='expenses_paid_by_employee'
                        label='Paid by Employee'
                        value={Number(currentOrder?.expenses_paid_by_employee)}
                        error={errors.expenses_paid_by_employee}
                        onChange={onChange}
                        readOnly={viewReadOnly}
                        style={{ marginTop: '40px' }}
                      />
                    </>
                  )}
                  {currentOrder.order_type === 'insurance' && (
                    <Grid item sm={12} xs={12} style={{ marginTop: '40px' }}>
                      <FormControl className={classes.formControl} style={{ width: '100%' }}>
                        <InputLabel id='demo-mutiple-chip-label' style={{ top: '-20px' }}>
                          Job Types
                        </InputLabel>
                        <Select
                          labelId='demo-mutiple-chip-label'
                          id='demo-mutiple-chip'
                          multiple
                          style={{ width: '100%' }}
                          disabled={viewReadOnly}
                          value={assignedJobTypes}
                          onChange={handleChange}
                          input={<Input id='select-multiple-chip' />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {Array.isArray(selected)
                                ? selected.map((value) => <Chip key={value} label={value} className={classes.chip} />)
                                : null}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {jobTypesLabels?.map((name) => (
                            <MenuItem key={name} value={name} style={getStyles(name, assignedJobTypes, theme)}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <BSelectField
                    sm={4}
                    xs={12}
                    label='Order Status'
                    required
                    name='order_status_id'
                    value={currentOrder.order_status_id}
                    onChange={onChange}
                    error={errors.order_status_id}
                    items={convertArrayToKeyedObject(orderStatuses, 'id', 'title')}
                    readOnly={!currentOrder.id}
                  />
                  {currentOrder.order_type === 'private' && (
                    <BCheckBoxField
                      style={{ marginLeft: '-10px' }}
                      sm={12}
                      name='prepaid'
                      label='Prepaid'
                      value={Number(currentOrder?.prepaid)}
                      error={errors.prepaid}
                      onChange={onChange}
                    />
                  )}

                  <div style={{ paddingLeft: '10px', borderTop: '1px solid #ddd', display: 'block', width: '100%' }}>
                    <h2>Client Details</h2>
                  </div>
                  <BTextField
                    label='Client First Name'
                    name='customer_f_name'
                    value={currentOrder?.customer_f_name}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    error={errors.customer_f_name}
                  />
                  <BTextField
                    label='Client Last Name'
                    name='customer_l_name'
                    value={currentOrder?.customer_l_name}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    error={errors.customer_l_name}
                  />
                  <BSelectField
                    sm={4}
                    xs={12}
                    label='Client'
                    required
                    name='client_id'
                    value={currentOrder.client_id}
                    onChange={onChange}
                    error={errors.client_id}
                    items={convertArrayToKeyedObject(clients, 'id', 'name')}
                    readOnly={viewReadOnly}
                  />
                  {viewReadOnly && (
                    <Grid item xs={12} sm={4}>
                      <a href={`tel:${currentOrder?.phone_1}`}>
                        <BTextField
                          label='Phone'
                          name='phone_1'
                          sm={12}
                          value={
                            currentOrder?.phone_1?.length === 10
                              ? normalizeUsPhone(currentOrder?.phone_1)
                              : currentOrder?.phone_1
                          }
                          onChange={onChange}
                          error={errors.phone_1}
                          onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                          readOnly={viewReadOnly}
                        />
                      </a>
                    </Grid>
                  )}
                  {!viewReadOnly && (
                    <BTextField
                      label='Phone'
                      sm={4}
                      name='phone_1'
                      value={
                        currentOrder?.phone_1?.length === 10
                          ? normalizeUsPhone(currentOrder?.phone_1)
                          : currentOrder?.phone_1
                      }
                      onChange={onChange}
                      error={errors.phone_1}
                      onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                      readOnly={viewReadOnly}
                    />
                  )}
                  {viewReadOnly && (
                    <Grid item xs={12} sm={4}>
                      <a href={`tel:${currentOrder?.phone_2}`}>
                        <BTextField
                          label='Mobile'
                          sm={12}
                          name='phone_2'
                          value={
                            currentOrder?.phone_2?.length === 10
                              ? normalizeUsPhone(currentOrder?.phone_2)
                              : currentOrder?.phone_2
                          }
                          onChange={onChange}
                          error={errors.phone_2}
                          onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                          readOnly={viewReadOnly}
                        />
                      </a>
                    </Grid>
                  )}
                  {!viewReadOnly && (
                    <BTextField
                      label='Mobile'
                      sm={4}
                      name='phone_2'
                      value={
                        currentOrder?.phone_2?.length === 10
                          ? normalizeUsPhone(currentOrder?.phone_2)
                          : currentOrder?.phone_2
                      }
                      onChange={onChange}
                      error={errors.phone_2}
                      onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                      readOnly={viewReadOnly}
                    />
                  )}
                  <BTextField
                    label='Email'
                    name='email'
                    required={currentOrder?.order_type === 'private'}
                    value={currentOrder?.email}
                    error={errors.email}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    onKeyDown={onKeyDownHandlerOnlyAcceptEmailCharacters}
                  />
                </Grid>
              )}
              {permission === 'EMPLOYEE' && (
                <>
                  <h2>Order Details</h2>
                  <div>Order Type: {currentOrder.order_type}</div>
                  <div>Area: {currentOrder.area?.title}</div>
                  <div>
                    Hours interval: {currentOrder.interval?.min} - {currentOrder.interval?.max}
                  </div>
                  <div>Order Created: {moment(currentOrder.created_at).format('MM/DD/YYYY')}</div>
                  <div>Route Date: {currentOrder.date}</div>
                  <div>
                    Created By: {currentOrder.created_by?.f_name?.concat(' ').concat(currentOrder.created_by?.l_name)}
                  </div>
                  <div>Order Number: {currentOrder.order_number}</div>
                  {currentOrder.order_type === 'insurance' && (
                    <div>External Order Number: {currentOrder.external_order_number}</div>
                  )}
                  {currentOrder.order_type === 'insurance' && <div>Claim Number: {currentOrder.claim_number}</div>}
                  {currentOrder.order_type === 'insurance' && <div>Invoice Number: {currentOrder.invoice_number}</div>}
                  {currentOrder.order_type === 'insurance' && (
                    <div>Company Type: {currentOrder.company_type?.name}</div>
                  )}
                  <div>Travel Price: {currentOrder.travel_price}</div>
                  {currentOrder.order_type === 'insurance' && (
                    <div>
                      Job types:{' '}
                      {currentOrder?.job_types?.map((jobtype, index) => (
                        <span key={jobtype.id}>
                          {jobtype.title} {index !== currentOrder?.job_types?.length - 1 ? ' - ' : ''}
                        </span>
                      ))}
                    </div>
                  )}
                  {currentOrder.order_type === 'private' && <div>Expenses: {currentOrder.expenses}</div>}
                  {currentOrder.order_type === 'private' && (
                    <div>
                      Expenses Paid By Employee: {currentOrder.expenses_paid_by_employee === '1' ? 'Yes' : 'No'}
                    </div>
                  )}
                  <div>Bonus: {currentOrder.bonus?.bonus_amount}</div>
                  <div style={{ borderTop: '1px solid #ddd', display: 'block', width: '100%' }}>
                    <h2>Client Details</h2>
                  </div>
                  {(currentOrder.customer_f_name || currentOrder.customer_l_name) && (
                    <div>
                      {' '}
                      Client name: {currentOrder.customer_f_name} {currentOrder.customer_l_name}
                    </div>
                  )}
                  {currentOrder.order_type === 'insurance' && <div>Client: {currentOrder.client.name}</div>}
                  <div>
                    {' '}
                    Phone:
                    <a href={`tel:${currentOrder?.phone_1}`}>
                      {' '}
                      {currentOrder?.phone_1?.length === 10
                        ? normalizeUsPhone(currentOrder?.phone_1)
                        : currentOrder?.phone_1}{' '}
                    </a>
                  </div>
                  <div>
                    {' '}
                    Mobile:
                    <a href={`tel:${currentOrder?.phone_2}`}>
                      {' '}
                      {currentOrder?.phone_2?.length === 10
                        ? normalizeUsPhone(currentOrder?.phone_2)
                        : currentOrder?.phone_2}{' '}
                    </a>
                  </div>
                  <div>
                    Email:
                    <a href={`mailto:${currentOrder?.email}`}> {currentOrder.email} </a>
                  </div>
                  <div>
                    {' '}
                    Address:
                    <a
                      href={
                        userLocation[0] && userLocation[1]
                          ? `https://www.google.com/maps/dir/?api=1&origin=${userLocation[0]},${userLocation[1]}&destination=${currentOrder.latitude},${currentOrder.longitude}&dir_action=navigate`
                          : `https://www.google.com/maps/dir/?api=1&destination=${currentOrder.latitude},${currentOrder.longitude}&dir_action=navigate`
                      }
                      // href={
                      //   userLocation[0] && userLocation[1]
                      //     ? `https://www.google.com/maps/dir/?api=1&origin=${userLocation[0]},${userLocation[1]}&destination=${currentOrder.latitude},${currentOrder.longitude}&dir_action=navigate`
                      //     : `https://www.google.com/maps/dir/?api=1&destination=${currentOrder.latitude},${currentOrder.longitude}&dir_action=navigate`
                      // }
                      target='_blank'
                      rel='noreferrer'
                    >
                      {' '}
                      {currentOrder.full_address}{' '}
                    </a>
                  </div>
                </>
              )}
              {permission === 'EMPLOYEE' && (
                <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                  <div style={{ borderTop: '1px solid #ddd', display: 'block', width: '100%' }}>
                    <h2>Chat</h2>
                  </div>
                  <ChatPage
                    userId={currentOrder.user_id}
                    orderId={currentOrder.id}
                    orderPage
                    updateChatList={updateChatList}
                  />
                </div>
              )}
              <br />
              {permission === 'ADMIN' && (
                <Grid container spacing={1}>
                  <Grid
                    item
                    sm={12}
                    style={{ marginTop: '20px', height: '380px', width: '100%' }}
                    onClick={() => openMap()}
                  >
                    <Map
                      key={rerenderMap}
                      center={currentOrder.latitude ? [currentOrder.latitude, currentOrder.longitude] : null}
                      marker={{
                        position: [currentOrder.latitude, currentOrder.longitude],
                        popup: '',
                      }}
                      onNewMarkerMoved={handleNewMarkMoved}
                      setAddress={setAddress}
                      geoCoder
                    />
                  </Grid>
                </Grid>
              )}
              {permission === 'ADMIN' && (
                <BTextField
                  sm={12}
                  xs={12}
                  label='Full Address'
                  required
                  name='full_address'
                  value={currentOrder?.full_address}
                  error={errors?.full_address}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              )}
              <>
                <h3 style={{ paddingLeft: '12px' }}>Updates for Technician</h3>
                <Card style={{ margin: 10, maxHeight: '200px', overflowY: 'scroll' }}>
                  <CardContent>{updates}</CardContent>
                </Card>
                {permission === 'ADMIN' && (
                  <div style={{ padding: '5px' }}>
                    <BTextField
                      sm={6}
                      xs={6}
                      name='technician_message'
                      placeholder='Add a text for the "Updates for Technician" section'
                      value={techMsg}
                      error={errors?.full_address}
                      onChange={(val) => setTechMsg(val.currentTarget.value)}
                    />
                    <Button
                      id='tech'
                      onClick={saveTechnician}
                      color='secondary'
                      variant='outlined'
                      style={{ marginTop: '10px' }}
                    >
                      save
                    </Button>
                  </div>
                )}
              </>
              {permission === 'ADMIN' && (
                <>
                  <h3>Description</h3>
                  <ReactQuill
                    theme='snow'
                    name='description'
                    value={currentOrder.description || ''}
                    onChange={(e) =>
                      setCurrentOrder((prevState) => ({
                        ...prevState,
                        description: e,
                      }))
                    }
                  />
                </>
              )}
              {permission === 'ADMIN' && (
                <>
                  <h3>Special Notes</h3>
                  <ReactQuill
                    theme='snow'
                    name='special_notes'
                    value={currentOrder.special_notes || ''}
                    onChange={(e) =>
                      setCurrentOrder((prevState) => ({
                        ...prevState,
                        special_notes: e,
                      }))
                    }
                  />
                </>
              )}
              {permission === 'EMPLOYEE' && (
                <div>
                  {' '}
                  <strong>Description:</strong>
                  <ReactQuill theme='bubble' name='description' value={currentOrder.description || ''} readOnly />
                </div>
              )}
              {permission === 'EMPLOYEE' && (
                <div>
                  {' '}
                  <strong>Special Notes:</strong>
                  <ReactQuill theme='bubble' name='special_notes' value={currentOrder.special_notes || ''} readOnly />
                </div>
              )}
            </CardContent>
            {paymentLogs.length > 0 && (
              <>
                <h3 style={{ paddingLeft: '12px' }}>Payments History</h3>
                <Card style={{ margin: 10, maxHeight: '200px', overflowY: 'scroll' }}>
                  <CardContent>{paymentLogs}</CardContent>
                </Card>
              </>
            )}
            <Inventory
              style={{ margin: 10 }}
              title='Previous Orders'
              items={ordersLinksData}
              onChange={(items) => addPreviousOrderId(items)}
              columns={columns}
              u={false}
              onRowClick={onRowClick}
            />
            <Divider />
            <Card style={{ margin: 10 }}>
              <CardHeader title='Additional Images' />
              {uploadedAdditional > 0 && (
                <Typography style={{ marginLeft: 15, width: '98%' }}>
                  <LinearProgress value={uploadedAdditional} />
                </Typography>
              )}
              <CardContent>
                <input type='file' multiple onChange={fileSelectedHandlerAdditionalImages} />
                <BImageList imageData={additionalImages} removeImg={removeImgAdditional} remove />
              </CardContent>
            </Card>
            <Card style={{ margin: 10 }}>
              <CardHeader title='Before Images' />
              {uploadedBefore > 0 && (
                <Typography style={{ marginLeft: 15, width: '98%' }}>
                  <LinearProgress value={uploadedBefore} />
                </Typography>
              )}
              <CardContent>
                <input type='file' multiple onChange={fileSelectedHandler} />
                <BImageList imageData={beforeImages} removeImg={removeImgBefore} remove />
              </CardContent>
            </Card>
            <Card style={{ margin: 10 }}>
              <CardHeader title='After Images' />
              {uploadedAfter > 0 && (
                <Typography style={{ marginLeft: 15, width: '98%' }}>
                  <LinearProgress value={uploadedAfter} />
                </Typography>
              )}
              <CardContent>
                <input type='file' multiple onChange={fileSelectedHandlerAfterImages} />
                <BImageList imageData={afterImages} removeImg={removeImgAfter} remove />
              </CardContent>
            </Card>
            {(deletedImages.length > 0 || deletedImagesBefore.length > 0 || deletedImagesAfter.length > 0) &&
              permission === 'ADMIN' && (
                <Card style={{ margin: 10 }}>
                  <CardHeader title='Deleted Images' />
                  <CardContent>
                    <BImageList imageData={deletedImages.concat(deletedImagesBefore).concat(deletedImagesAfter)} />
                  </CardContent>
                </Card>
              )}
            <CardContent>
              <>
                <h3>Technician Report</h3>
                <ReactQuill
                  theme='snow'
                  name='technician_report'
                  value={currentOrder.technician_report || ''}
                  onChange={(e) =>
                    setCurrentOrder((prevState) => ({
                      ...prevState,
                      technician_report: e,
                    }))
                  }
                />
              </>
            </CardContent>
            <BCheckBoxField
              sm={4}
              name='approved_by_admin'
              label='Approved By Admin'
              value={Number(currentOrder?.approved_by_admin)}
              error={errors.approved_by_admin}
              onChange={onChange}
              readOnly={viewReadOnly || permission === 'EMPLOYEE' || String(currentOrder.order_status_id) === '1'}
              style={{ marginTop: '40px' }}
            />
            <BCheckBoxField
              sm={4}
              name='report'
              label='Report'
              value={Number(currentOrder?.report)}
              error={errors.report}
              onChange={onChange}
              readOnly={
                viewReadOnly ||
                permission === 'EMPLOYEE' ||
                (String(currentOrder.order_status_id) !== '1' &&
                  (String(currentOrder.approved_by_admin) === '0' || !currentOrder.approved_by_admin)) ||
                String(currentOrder.order_status_id) === '1'
              }
              style={{ marginTop: '40px' }}
            />
            {permission === 'ADMIN' && String(currentOrder.order_status_id) !== '1' && (
              <BCheckBoxField
                sm={4}
                name='reopen'
                label='Reopen'
                value={Number(currentOrder?.reopen)}
                error={errors.reopen}
                onChange={onChange}
                readOnly={viewReadOnly}
                style={{ marginTop: '40px' }}
              />
            )}
            {permission === 'ADMIN' && currentOrder.id && (
              <Grid item xs={12} sm={6} style={{ padding: '15px' }}>
                <Button id='newOrdrer' onClick={newOrderAdmin} color='secondary' variant='outlined'>
                  Create a new order
                </Button>
              </Grid>
            )}
            {permission === 'EMPLOYEE' && (
              <div style={{ padding: '10px' }}>
                <BSelectField
                  sm={4}
                  xs={12}
                  label='Order Status'
                  required
                  name='order_status_id'
                  value={currentOrder.order_status_id}
                  onChange={onChange}
                  error={errors.order_status_id}
                  items={convertArrayToKeyedObject(orderStatuses, 'id', 'title')}
                  readOnly={!currentOrder.id}
                />
                <Button
                  id='closeOrder'
                  onClick={closeOrder}
                  color='secondary'
                  variant='outlined'
                  disabled={currentOrder?.order_status?.title !== 'Open' && currentOrder.reopen === '0'}
                  style={{ marginTop: '10px' }}
                >
                  Close Order
                </Button>
                {currentOrder.order_type === 'private' && (
                  <BCheckBoxField
                    style={{ marginLeft: '-10px' }}
                    sm={12}
                    name='prepaid'
                    label='Prepaid'
                    value={Number(currentOrder?.prepaid)}
                    error={errors.prepaid}
                    onChange={onChange}
                    readOnly
                  />
                )}

                <Grid item xs={12} sm={6} style={{ marginTop: '15px' }}>
                  <BSubmitButton
                    disabled={currentOrder?.order_status?.title !== 'Open'}
                    id='closeEmployee'
                    label={currentOrder.id ? 'Save Order' : 'Create Order'}
                    loading={loading}
                    extraIcon={<CloudUploadIcon style={{ marginLeft: '10px', fontSize: '18px' }} />}
                  />
                </Grid>
              </div>
            )}
            <br />
            {currentOrder.id && openPdf && PdfContain}
            <br />
            <CardActions style={{ marginTop: '20px' }}>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && permission === 'ADMIN' && (
                  <BSubmitButton
                    disabled={currentOrder?.order_status?.title === 'Paid'}
                    id='submitEmployee'
                    label={currentOrder.id ? 'Save Order and Pdf' : 'Create Order'}
                    loading={loading}
                  />
                )}
                {permission === 'EMPLOYEE' && (
                  <Button id='newOrdrer' onClick={newOrderEmployee} color='secondary' variant='outlined'>
                    Create a new order
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: permission === 'ADMIN' ? '/order-management/orders' : '/employees/orders',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  Back to Orders list
                </Button>
              </Grid>
            </CardActions>
          </form>
          <BHistoryNavigator
            historyStep={historyStep}
            historyIdList={historyIdList}
            prevHistory={prevHistory}
            nextHistory={nextHistory}
          />
        </Card>
      )}
    </div>
  );
};

OrdersPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default OrdersPage;
